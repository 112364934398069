import { Price } from './Price'

class Rate extends Price {
  constructor (rawRate, priceType) {
    super(rawRate['Rate'][priceType], '#text')

    this.id = rawRate['@RateId']
    this.name = rawRate['Name']
    this.rate = rawRate['Rate']
    this.weight = rawRate['Weight']
    this.restrictions = rawRate['Restrictions']
    this.priceType = this.rate[priceType]["@PriceType"]
    this.minStay = 1
    if (rawRate['Rate']['StayTotal']) {
      this.totalPrice = new Price(rawRate['Rate']['StayTotal'], 'Price')
    }
    if (this.restrictions?.StayMin) {
      this.minStay = parseInt(this.restrictions?.StayMin)
    }
  }
}

export { Rate }
