import { AppError, ErrorLevel } from "./Errors";

class Unit {
  get isAvailable() {
    return this.availableUnits > 0
  }

  get isOnRequest() {
    return !this.isAvailable
  }

  get lowestMember() {
    if (!this.rates) {
      return
    }
    const memberRates = this.rates
      .map(r => r.member)
      .filter(r => r && r.price && r.price > 0)
      .sort((a, b) => a.price - b.price)

    if (memberRates.length) {
      return { price: memberRates[0].price, currency: memberRates[0].currency, display: memberRates[0].display }
    }
    return
  }

  get lowestRegular() {
    if (!this.rates || !this.rates.length) {
      return
    }
    const regularRates = this.rates
      .map(r => r.regular)
      .sort((a, b) => a.price - b.price)
    return { price: regularRates[0].price, currency: regularRates[0].currency, display: regularRates[0].display }
  }

  constructor (rawUnit) {
    this.id = rawUnit['@UnitId']
    this.name = rawUnit['Name']
    this.description = rawUnit.description
    this.image = rawUnit.image
    this.gallery = rawUnit.gallery
    this.weight = rawUnit.weight
    this.maxChildAge = rawUnit.maxChildAge
    this.subProperty = rawUnit.subProperty
    this.hideIfUnavailable = rawUnit.hideIfUnavailable ?? false,
    this.occupancyMax = parseInt(rawUnit['@OccupancyMax'])
    this.occupancyMin = parseInt(rawUnit['@OccupancyMin'])
    this.occupancyMaxAdult = rawUnit['@OccupancyMaxAdult'] ? parseInt(rawUnit['@OccupancyMaxAdult']) : this.occupancyMax
    this.occupancyMinAdult = rawUnit['@OccupancyMinAdult'] ? parseInt(rawUnit['@OccupancyMinAdult']) : 0
    this.occupancyMaxChildren = rawUnit['@OccupancyMaxChd'] ? parseInt(rawUnit['@OccupancyMaxChd']) : this.occupancyMax
    this.occupancy  = parseInt(rawUnit['@Occupancy'])
    this.area = rawUnit.area
    if (this.occupancyMaxAdult < 1) {
      throw new AppError('OccupancyMaxAdultError', ErrorLevel.FATAL)
    }
    this.occupancyDisplayAdult = this.occupancyMaxAdult
    this.occupancyDisplayChildren = this.occupancyMax - this.occupancyMaxAdult
    this.availableUnits = rawUnit['@AvailableUnits'] ? parseInt(rawUnit['@AvailableUnits']) : 0

    // use #testavailability={unitId:availableUnits,unitId2:availableUnits} to set available units for testing
    const regex = /#testavailability=\{([^}]+)\}/;
    const match = regex.exec(location.hash);
    if (match) {
        const pairs = match[1].match(/(\d+):(\d+)/g);
        pairs.forEach(pair => {
            const [key, value] = pair.split(':');
            if (key === this.id) {
                this.availableUnits = parseInt(value);
            }
        });
    }

    this.rates = []
    this._extras
    this.vehicleType = null
    this.vehicleLength = null
  }

  set extras(value) {
    if(this._extras) return
    if (Array.isArray(value)) {
      this._extras = value.join("<br>")
    } else if (typeof value === "string") {
      this._extras = value
    } else if (typeof value === "object") {
      this._extras = value.Extra
    }
  }

  get extras() {
    return this._extras
  }

  isNumberOfChildrenAllowed(children) {
    return children < 1 || this.occupancyMaxChildren
  }

  requiredNumberOfUnits(adults, children) {
    if (!this.isNumberOfChildrenAllowed(children)) {
      throw new AppError('notAcceptingChildren', ErrorLevel.WARNING)
    }

    const possiblePersons = {
      adultsOnly: this.occupancyMax - this.occupancyMaxChildren || 1,
      childrenOnly: this.occupancyMax - this.occupancyMaxAdult,
      mixed: 0
    }
    possiblePersons.mixed = this.occupancyMax - (possiblePersons.adultsOnly + possiblePersons.childrenOnly)

    const childrenAndMixed = possiblePersons.childrenOnly + possiblePersons.mixed
    const unitLimits = {
      adultsMin: Math.ceil(adults / (possiblePersons.adultsOnly + possiblePersons.mixed)),
      adultsMax:  this.occupancyMinAdult ? Math.ceil(adults / this.occupancyMinAdult) : adults,
      childrenMin: childrenAndMixed ? Math.ceil(children / (childrenAndMixed)) : 0,
      min: Math.ceil((children + adults) / this.occupancyMax),
    }

    if (unitLimits.childrenMin > unitLimits.adultsMax) {
      throw new AppError('notEnoughAdults', ErrorLevel.WARNING)
    }

    return Math.max(unitLimits.adultsMin, unitLimits.childrenMin, unitLimits.min)
  }
}

export { Unit }
