/* global Vue */

export default {
  name: 'CountriesDropdown',
  template: `
    <label :for="id">{{ label }}</label>
    <select
      :id="id"
      class="select-input"
      v-model="code"
      @change="$emit('countryChanged', countryObject)">
      <option disabled selected>{{ $t('select') }}</option>
      <option
        v-for="country in countries"
        :value="country.code">
        {{ country.name }}
      </option>
    </select>
    <span class="field-error" v-show="error !== ''">{{ error }}</span>
  `,

  props: {
    countryCode: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['countryChanged'],

  setup (props, { emit }) {
    const { countryCode } = Vue.toRefs(props)
    const code = Vue.ref('')
    const countries = Vue.ref([])
    const error = Vue.ref('')

    const countryObject = Vue.computed(() => {
      return countries.value.find(obj => obj.code === code.value)
    })

    Vue.onMounted(async () => {
      try {
        let response
        if (props.source === 'laserline') {
          response = await fetch('/json/laserlineCountries.json')
        } else {
          response = await fetch('/json/countries.json')
        }
        countries.value = await response.json()

        Vue.watch(countryCode, () => {
          if (countries.value.length === 0 || countryCode.value.length < 2) return
          if (props.source === 'laserline' || countryCode.value.length === 2) {
            code.value = countryCode.value
            emit('countryChanged', countryObject.value)
          } else if (countryCode.value.length === 3) {
            code.value = countries.value.find(obj => obj.code_3 === countryCode.value)?.code
            emit('countryChanged', countryObject.value)
          }
        }, { immediate: true })
      } catch (err) {
        error.value = err
      }
    })

    return {
      countries,
      countryObject,
      code,
      error,
    }
  },
}
