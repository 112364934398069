/* global Headers, Element, CustomEvent, EventTarget, msal, fetch, LOYALTY_API, TENANT_NAME, LOCALE, PREFIX */

// https://cameronnokes.com/blog/build-your-own-event-emitter-using-only-native-dom-apis/

import { loginRequest, config, b2cPolicies, passwordResetTPF, passwordResetRedirectLocation } from './auth.config.js'
import { ErrorLevel, AppError, BeternaError, RestError } from './classes/Errors.js'

class EventEmitter {
  constructor () {
    if (EventTarget) {
      this._target = new EventTarget()
    } else {
      this._target = new Element()
    }
  }

  on (eventName, listener) {
    return this._target.addEventListener(eventName, listener)
  }

  once (eventName, listener) {
    return this._target.addEventListener(eventName, listener, { once: true })
  }

  off (eventName, listener) {
    return this._target.removeEventListener(eventName, listener)
  }

  emit (eventName, detail) {
    return this._target.dispatchEvent(
      new CustomEvent(eventName, { detail, cancelable: true }),
    )
  }
}

// Helper function to call MS Graph API endpoint
// using authorization bearer token scheme_
async function fetchWithTokenAsync (endpoint, accessToken, method, body) {
  if (!accessToken) {
    throw new Error('not authenticated')
  }

  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`
  headers.append('Authorization', bearer)
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  if (body) {
    body = JSON.stringify(body)
  }
  const response = await fetch(endpoint, { method, headers, body })
  let raw = null
  if (response.ok) {
    raw = await response.json()
  } else {
    throw new RestError('fetchWithTokenAsync', response.status, raw, { url: endpoint, body: body })
  }

  if (raw?.status === 1 && raw?.message === 'User is authenticated, but CRM profile was not yet created.') {
    console.warn(raw.message)
    return raw
  }

  if (raw?.status !== 0) {
    throw new BeternaError('fetchWithTokenAsync', ErrorLevel.FATAL, raw, { url: endpoint, body: body })
  }

  return raw
}

class _AuthService extends EventEmitter {
  constructor () {
    super()

    // eslint-disable-next-line no-unused-vars
    const _idTokenClaims = null
    // eslint-disable-next-line no-unused-vars
    const _accountId = null
    // eslint-disable-next-line no-unused-vars
    const _accessToken = null
    // eslint-disable-next-line no-unused-vars
    const _loyaltyId = -1

    this._loginRequest = loginRequest

    this._isFinished = false

    if (!msal) {
      console.error('MSAL not loaded.')
      this.handleFinishedNoAuth()
      return
    }

    this.msalInstance = new msal.PublicClientApplication(config)

    // can't use async in constructor
    this.msalInstance.handleRedirectPromise()
      .then(resp => {
        if (resp && resp.accessToken && resp.account) {
          this.loadToken(resp)
          this.handleFinishedNoAuth()

          // } else if (resp && !resp.accessToken && resp.account)  {
          //  console.error("missing access token. API scope problem?")
          //  this.emit("ended", {})
          this.emit('signedIn')
        } else {
          this.handleSilentLoading()
        }
      })
      .catch((error) => {
        if (!error.errorMessage) {
          console.error(error)
          this.handleFinishedNoAuth()
        } else if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          const forgotPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            knownAuthorities: [`${TENANT_NAME}.b2clogin.com`],
            scopes: [`https://${TENANT_NAME}.onmicrosoft.com/api/base`],
            extraQueryParameters: { ui_locales: LOCALE === 'si' ? 'sl' : LOCALE },
          }
          window.sessionStorage.setItem(this.passwordChangeSessionKey, true)
          this.msalInstance.loginRedirect(forgotPasswordRequest)
        } else if (error.errorMessage.indexOf('AADB2C90091') > -1) {
          this.handleSilentLoading()
        }
      })

    window.onpageshow = (event) => {
      if (event.persisted) this.clearBrowserStorage()
    }
  }

  handleSilentLoading () {
    const urlParams = new URLSearchParams(window.location.search)
    const requestedAccountId = urlParams.get('accountId')
    if (requestedAccountId?.length > 10) {
      const activeAccount = {
        homeAccountId: requestedAccountId,
      }
      this._loginRequest.account = activeAccount
      this.msalInstance.loginRedirect(this._loginRequest)
    }

    const currentAccounts = this.msalInstance.getAllAccounts()
    if (currentAccounts && currentAccounts.length > 0) {
      this._accountId = currentAccounts[0].homeAccountId
      this._loginRequest.account = this.msalInstance.getAccountByHomeId(this._accountId)

      const tokenRequest = currentAccounts[0]?.homeAccountId.indexOf('b2c_1_m_activation') > -1
        ? {
            ...this._loginRequest,
            authority: b2cPolicies.authorities.activateLoyalty.authority,
            knownAuthorities: [`${TENANT_NAME}.b2clogin.com`],
          }
        : this._loginRequest

      this.msalInstance.acquireTokenSilent(tokenRequest)
        .then(resp => {
          if (resp.account) {
            this.loadToken(resp)
          }
        })
        .then(() => {
          this.handleFinishedNoAuth()
        }).catch((error) => {
          console.error('acquireTokenSilent error', error)
        })
    } else {
      this.handleFinishedNoAuth()
    }
  }

  clearBrowserStorage () {
    this.msalInstance.browserStorage.clear()
  }

  handleFinishedNoAuth () {
    this._isFinished = true
    this.emit('ended', {})
  }

  loadToken (resp) {
    if (!resp.accessToken || !resp.account) {
      this._idTokenClaims = ''
      this._accountId = ''
      this._accessToken = ''
      return
    }
    this._idTokenClaims = resp.idTokenClaims
    this._accountId = resp.account.homeAccountId
    this._accessToken = resp.accessToken

    this.fetchAndSaveUserData()
  }

  fetchAndSaveUserData () {
    fetchWithTokenAsync(`${LOYALTY_API}/api/v1/loyaltyprofiles/me`, this._accessToken, 'GET')
      .then(obj => {
        this._loyaltyId = parseInt(obj?.userContact?.loyaltyProfileId)
        this._memberProfile = obj?.userContact

        if (this._idTokenClaims.tfp === passwordResetTPF && window.sessionStorage.getItem(this.passwordChangeSessionKey)) {
          window.sessionStorage.removeItem(this.passwordChangeSessionKey)
          window.location.replace(PREFIX + passwordResetRedirectLocation)
        }
      })
      .then(() => {
        this._isFinished = true
        const result = {
          displayName: this.getDisplayName(),
          accountId: this._accountId,
          loyaltyId: this._loyaltyId,
        }
        this.emit('authenticated', result)
      })
      .catch(e => {
        console.error(e)
        if (e instanceof RestError) {
          this.emit('error', e)
        } else if (e instanceof BeternaError) {
          this.emit('error', e)
        } else {
          this.emit('error', new AppError('me', ErrorLevel.FATAL))
        }
      })
  }

  async signIn () {
    this.msalInstance.loginRedirect(this._loginRequest)
  }

  openLoyaltyActivation () {
    this.msalInstance.loginRedirect(b2cPolicies.authorities.activateLoyalty)
  }

  openEditProfile () {
    this.msalInstance.loginRedirect(b2cPolicies.authorities.editProfile)
  }

  signOut () {
    this.emit('signingOut', {})
    const currentAccount = this.msalInstance.getAccountByHomeId(this._accountId)
    this.msalInstance.logout({
      account: currentAccount,
    })
  }

  get isAuthenticated () {
    return this._accessToken?.length > 5
  }

  get isMember () {
    return this.isAuthenticated && this._loyaltyId > 0
  }

  get accessToken () {
    return this._accessToken
  }

  get accountId () {
    return this._accountId
  }

  getDisplayName () {
    if (this._memberProfile) {
      return `${this._memberProfile.firstName} ${this._memberProfile.lastName}`
    }
    if (!this._idTokenClaims) {
      return null
    }
    let result = ''
    if (this._idTokenClaims.given_name) {
      result += this._idTokenClaims.given_name + ' '
    }
    if (this._idTokenClaims.family_name) {
      result += this._idTokenClaims.family_name + ' '
    }
    return result
  }

  get isFinished () {
    return this._isFinished
  }

  get firstName () {
    return this._idTokenClaims?.given_name
  }

  get memberProfile () {
    return this._memberProfile
  }

  get lastName () {
    return this._idTokenClaims?.family_name
  }

  get email () {
    // eslint-disable-next-line no-useless-return --- @samo4 please fix
    if (!this._idTokenClaims?.emails?.length) return
    else return this._idTokenClaims.emails[0]
  }

  get loyaltyId () {
    return this._loyaltyId
  }

  get passwordChangeSessionKey () {
    return `forgot-password-${this.msalInstance.config.auth.clientId}`
  }
}

// singleton
// eslint-disable-next-line no-unused-vars
const AuthService = new _AuthService()

export { fetchWithTokenAsync, AuthService }
export default AuthService
