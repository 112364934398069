import { Price } from './Price'

class PriceQuote {
  constructor (rawQuote) {
    if (typeof rawQuote === 'object' && rawQuote.NoAvailibiliy) {
      console.warn(`rawQuote.NoAvailibiliy: ${rawQuote.NoAvailibiliy}`)
      return
    }

    if (typeof rawQuote !== 'object' || !rawQuote.RateId)  {
      throw new TypeError("wrong argument")
    }

    this.rateId = rawQuote.RateId
    this.ratePolicy = [...rawQuote.RatePolicy.Policy]
    this.grandTotal = new Price(rawQuote.Price, '@GrandTotal')
    this.youSave = null

    // const basePrice = rawQuote.Units.Unit
    //   .reduce((unitAcc, unit) => unitAcc + unit.Price.PriceItems.PriceItem
    //     .filter(item => item['@Type'] === 'BASE')
    //     .reduce((itemAcc, item) => itemAcc + parseFloat(item.Value['#text']), 0)
    //   , 0)

    // console.log(basePrice)

    let basePrice = 0

    this.units = []
    rawQuote.Units.Unit.forEach(u => {
      const unit = { unitId: u["@UnitId"] }
      unit.total = new Price(u.Price, '@UnitTotal')
      unit.board = u.Price.Board
      unit.priceItems = u.Price.PriceItems?.PriceItem.map(pi => {
        return {
          type: pi["@Type"],
          name: pi.Name,
          valueType: pi.Value['@ValueType'],
          price: new Price(pi.Value, '#text', u.Price),
        }
      }) || []
      unit.services = u.AdditionalServices?.Service.map(rawService => {
        return {
          id: rawService["@ServiceId"],
          name: rawService.Name,
          maxQuantity: parseInt(rawService['@MaxQuantity']),
          priceType: rawService.Price['@PriceType'],
          price: new Price(rawService.Price, '#text'),
          ...( rawService.Description ? { serviceGroup: rawService.Description } : {}), // this is an intentional workaround because phobs does not provide service groups
        }
      }) || []
      this.units.push(unit)

      basePrice += unit.priceItems.filter(item => item.type == 'BASE').reduce((itemAcc, item) => itemAcc + item.price.price, 0)
    })

    if (rawQuote.Price['@AlternateTotal']) {
      this.alternateTotal = new Price(rawQuote.Price, '@AlternateTotal')
      this.youSave = new Price(this.alternateTotal.price - this.grandTotal.price, 'EUR')
    }

    this.guaranteeMethods = []
    rawQuote.AllowedPaymentMethods?.MethodGroup.forEach(raw => {
      const apm = {
        type: raw['@Type']
      }
      if (apm.type.startsWith('CCARD')) {
        apm.paymentMethods = raw['PaymentMethod'].map(rawPm => {
            if (rawPm['@CreditCardType']) {
              return { creditCardType: rawPm['@CreditCardType'] }
            } else if (rawPm['@ValueType']) {
              throw new Error('unsupported payment method')
            } else {
              throw new Error('unsupported payment method')
            }
          })
      } else if (apm.type === 'BANKTRANSFER') {
        if (raw['PaymentMethod']['@Value'] && raw['PaymentMethod']['@ValueType'] && raw['PaymentMethod']['@ValueType'] === 'PERC') {
          apm.value = raw['PaymentMethod']['@Value']
          apm.valueType = '%'
          apm.text = raw['PaymentMethod']['#text']
        } else if (raw['PaymentMethod']['@Value'] && raw['PaymentMethod']['@ValueType'] && raw['PaymentMethod']['@ValueType'] === 'FIXED') {
          apm.value = raw['PaymentMethod']['@Value']
          apm.valueType = raw['PaymentMethod']['@Currency']
          apm.text = raw['PaymentMethod']['#text']
        } else {
          throw new Error('unsupported payment method')
        }
      } else if (apm.type === 'NOGUARANTEE') {
        // nothing to do
      } else {
        throw new Error(`unsupported payment method : ${apm.type}`)
      }

      this.guaranteeMethods.push(apm)
    })
  }
}

export default PriceQuote
