/* global TENANT_NAME, CLIENT_ID, REDIRECT_URI, msal, LOCALE, IS_BOOKING, PREFIX */

const extraQueryParameters = { ui_locales: LOCALE === 'si' ? 'sl' : LOCALE }

const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/B2C_1_m_signupsignin`,
      extraQueryParameters,
    },
    forgotPassword: {
      authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/B2C_1_m_password_reset`,
      extraQueryParameters,
    },
    editProfile: {
      authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/B2C_1_m_profile_flow`,
      extraQueryParameters,
    },
    activateLoyalty: {
      authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/B2C_1_m_activation`,
      extraQueryParameters,
    },
  },
}

const config = {
  auth: {
    clientId: CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [`${TENANT_NAME}.b2clogin.com`],
    redirectUri: `${REDIRECT_URI}`,
    ...(IS_BOOKING && { postLogoutRedirectUri: `${REDIRECT_URI}${PREFIX}/step1` }),
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message)
            return
          case msal.LogLevel.Info:
            console.info(message)
            return
          case msal.LogLevel.Verbose:
            console.debug(message)
            return
          case msal.LogLevel.Warning:
            console.warn(message)
        }
      },
    },
  },
}

const loginRequest = {
  // prompt: "select_account",
  // "openid", "profile",
  scopes: [`https://${TENANT_NAME}.onmicrosoft.com/api/base`],
  extraQueryParameters: { ui_locales: LOCALE === 'si' ? 'sl' : LOCALE },
}

const tokenRequest = {
  scopes: ['User.Read', 'Mail.Read'],
  forceRefresh: false,
}

const passwordResetTPF = 'B2C_1_m_password_reset'
const passwordResetRedirectLocation = '/loyalty/account'

export { tokenRequest, loginRequest, config, b2cPolicies, passwordResetTPF, passwordResetRedirectLocation }
