import CldImg from '../components/CldImg'

export default {
  name: 'LoyaltyBenefits',
  components: { CldImg },
  template: `
    <div class="see-you-soon section-padding section-margin-small-top">
      <CldImg src="https://res.cloudinary.com/maistra/image/upload/v1695889714/Other/Rovinj_qnbhb0.jpg" />

      <div class="container grid">
        <p class="h1 col-12 col-sm-11 offset-sm-1">{{ $t('seeYouSoon') }}</p>
      </div>
    </div>
  `,
}
