/* global WWW_HOME_URL, HOME_TITLE, LOGO, CONTEXT, LOCALE */

import SimpleHeader from './SimpleHeader'
import { isDebugEnabled } from '../../utils-common'

export default {
  name: 'FatalError',
  components: { SimpleHeader },
  template: `
<div class="fatal-error">
  <SimpleHeader showPhone="true" />

  <div class="fatal-error__content section-padding">
    <div class="grid">
      <h1 class="col-11 offset-1 col-md-10 offset-md-2" v-if="error.code && $t('fatalError.code.' + error.code) != 'fatalError.code.' + error.code">
        {{ $t('fatalError.code.' + error.code) }}
      </h1>
      <h1 class="col-11 offset-1 col-md-10 offset-md-2" v-else>{{ $t('fatalError.title') }}</h1>
      <hr class="col-11 col-md-9">
      <div class="col-10 offset-1 col-md-7 offset-md-2 grid">
        <div class="col-10 col-md-7">{{ $t('fatalError.message') }}</div>
        <div class="col-10 col-md-5 fatal-error__button">
          <a :href="homeUrl" class="button" role="button">{{ $t('fatalError.button') }}</a>
        </div>
        <div v-if="isDebugEnabled || (CONTEXT !== 'production')" class="col-12 fatal-error__details">
          <p v-if="(typeof error === 'string')">{{ error }}</p>
          <p v-else v-for="p in Object.getOwnPropertyNames(error)">{{ p }} : {{ error[p] }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
  `,
  props: { error: Object },
  setup (props) {
    const homeUrl = `${WWW_HOME_URL}/${LOCALE !== 'en' ? LOCALE : ''}`

    return {
      isDebugEnabled,
      HOME_TITLE,
      LOGO,
      CONTEXT,
      homeUrl,
    }
  },
}
