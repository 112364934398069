/* global PREFIX, WWW_HOME_URL, VueI18n */

import { useCart, setBecomeMember } from '../../use-cart'

export default {
  name: 'LoyaltyInvite',
  template: `
<div v-show="!(isMember || cart.accessCode.value)" class="loyalty-banner loyalty-banner--invite">
  <div class="loyalty-banner__wrapper">
    <div class="loyalty-banner__logo">
      <img src="https://res.cloudinary.com/maistra/image/upload/v1636445351/Loyalty/maistar-rewards-club.png" alt="Maistar Rewards Club" width="106" height="96">
    </div>
    <div class="loyalty-banner__text">
      <h4>{{ $t('loyaltyInvite.maiStarClub') }}</h4>
      <p>{{ $t('loyaltyInvite.becomeMessage') }}</p>

      <label class="loyalty-banner__radio" for="join-maistar-yes">
        <input checked type="radio" id="join-maistar-yes" name="join-maistar" v-model="becomeMember" value="true">
        <i18n-t tag="span" keypath="loyaltyInvite.confirmedMembership" scope="global">
          <template #termsAndConditions>
            <a :href="WWW_HOME_URL + PREFIX + '/loyalty-terms-and-conditions'" target="_blank">{{ $t('loyaltyInvite.termsAndConditions') }}</a>
          </template>
          <template #privacyNotice>
            <a :href="WWW_HOME_URL + PREFIX + '/loyalty-privacy-notice'" target="_blank">{{ $t('loyaltyInvite.privacyNotice') }}</a>
          </template>
        </i18n-t>
      </label>

      <label class="loyalty-banner__radio" for="join-maistar-no" v-if="!cart.isAuthenticated.value">
        <input type="radio" id="join-maistar-no" name="join-maistar" v-model="becomeMember" value="false">
        <span v-html="$t('loyaltyInvite.unconfirmedMembership')" />
      </label>

      <p v-if="!cart.isAuthenticated.value">{{ $t('loyaltyInvite.alreadyMember') }} <a @click="$AuthService.signIn()" style="cursor:pointer;">{{ $t('loyaltyInvite.signIn') }}</a></p>
      <p v-else>{{ unconfirmedMembershipNoticeParts[0] }}<a @click="$AuthService.signOut()" style="cursor:pointer;">{{ $t('loyaltyInvite.unconfirmedMembershipNoticeLogout') }}</a>{{ unconfirmedMembershipNoticeParts[1] }}</p>
    </div>
  </div>
</div>
  `,
  props: { isMember: Boolean },
  setup () {
    const { cart } = useCart()
    console.log('isAuthenticated', cart.isAuthenticated.value);
    const becomeMember = Vue.ref(cart.becomeMember.value)

    Vue.watch(becomeMember, currentValue => {
      setBecomeMember(currentValue)
    })

    const { t } = VueI18n.useI18n()
    const unconfirmedMembershipNoticeParts = t('loyaltyInvite.unconfirmedMembershipNotice').split('[logout]')

    return {
      cart,
      becomeMember,
      unconfirmedMembershipNoticeParts,
      PREFIX,
      WWW_HOME_URL
    }
  }
}
