/* global Vue */
import Modal from './components/Modal'
import { setCurrency, useCurrencyObserver } from '../use-currency-observer'
import { messagesCommon } from '../i18n/translations-common'
import { CreateI18n } from '../i18n/create-i18n'

const i18n = CreateI18n(messagesCommon)

const LocaleSwitchApp = Vue.createApp({
  name: 'AuthStatus',
  components: { Modal },
  template: '#locale-switch-app-template',
  setup (props) {
    const isModalOpen = Vue.ref(false)
    const co = useCurrencyObserver().value
    const currency = Vue.ref(co === 'null' || co === null ? 'EUR' : co)
    const relUrl = Vue.ref(window.location.pathname)

    function update () {
      setCurrency(currency.value)
      if (window.location.pathname !== relUrl.value) {
        window.location.href = relUrl.value
      } else {
        isModalOpen.value = false
      }
    }

    return { isModalOpen, currency, relUrl, update }
  },
})

const appElement = document.getElementById('locale-switch-app')

if (appElement) {
  LocaleSwitchApp.use(i18n)
  LocaleSwitchApp.mount(appElement)
}

export default LocaleSwitchApp
