/* global Vue */

import AuthStatus from './components/AuthStatus'
import { Notifications } from './components/Notifications'
import AuthService from '../AuthService'

import { messagesSpecific } from '../i18n/translations'
import { CreateI18n } from '../i18n/create-i18n'

const i18nAuth = CreateI18n(messagesSpecific)

const AuthApp = Vue.createApp({
  name: 'AuthApp',
  components: { AuthStatus, Notifications },
  template: `
    <AuthStatus />
  `,
})

AuthApp.config.globalProperties.$AuthService = AuthService // same service is imported on all apps
AuthApp.use(i18nAuth)

const appElement = document.getElementById('auth-app')
if (appElement) AuthApp.mount(appElement)

export default AuthApp
