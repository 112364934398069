/* global Vue, CONTEXT */

import { isDebugEnabled } from '../../utils-common'

const Notification = {
  name: 'Notification',
  template: `
<div :class="'notification notification--' + type">
  <i :class="'notification__icon icon-' + icon"></i>
  <div>
    <h3>{{ details.title ? details.title : $t(title) }}</h3>
    <div v-if="areDetailsShown && details">
      <p v-if="details.code">{{ $t(details.code) }}</p>
      <p v-if="details.details">{{ details.details }}</p>
      <p v-if="details.message">{{ details.message }}</p>
      <div v-if="isDebugEnabled || (CONTEXT !== 'production')">
        <pre v-if="details.stack">{{ details.stack }}</pre>
      </div>
    </div>
    <a v-if="!areDetailsShown && details" role="button" @click="areDetailsShown = true">{{ $t('notification.moreInfo') }}</a>
  </div>

  <button
    @click="$emit('close')"
    :title="$t('close')">
    <i class="icon-close"></i>
  </button>
</div>
  `,
  props: {
    noRouter: {
      type: Boolean,
      default: false,
    },
    details: Object,
    type: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const icon = Vue.ref('check')
    const title = Vue.ref('notification.success')

    if (props.type === 'warning') {
      icon.value = 'warning'
      title.value = 'notification.error'
    } else if (props.type === 'error') {
      icon.value = 'error'
      title.value = 'notification.error'
    }

    const areDetailsShown = Vue.ref(false)

    return {
      icon,
      title,
      areDetailsShown,
      isDebugEnabled,
      CONTEXT,
    }
  },
}

export default Notification
