/* global Vue, AZURE_URI, LOCALE, IS_BOOKING */

import BookingAccessCode from './BookingAccessCode'
import BookingCalendar from './BookingCalendar'
import BookingModal from './BookingModal'
import Guests from './Guests'
import { displayDate, displayGuests } from '../../utils-common'
import { AvailabilityCalendar } from '../../classes/AvailabilityCalendar'
import { setErrorAndStopLoading } from './Notifications'

export default {
  name: 'BookingMenu',
  components: { BookingAccessCode, BookingCalendar, BookingModal, Guests },
  template: `
<div class="booking-menu">
  <div class="booking-menu__summary container">
    <span>
      {{ displayDate(bookingParams) }}
      <br>
      {{ displayGuests(bookingParams, $t, property?.maxChildAge === 0) }}
    </span>
    <button title="$t('bookingModal.updateBtn')" @click="openContainer()"><i class="icon-search"></i></button>
  </div>

  <div class="booking-menu__inputs container grid">
    <div class="col-12 col-md-3">
      <label for="dates">
        {{ $t('bookingModal.date') }}
        <i class="icon-caret-down"></i>
      </label>
      <input
        id="dates"
        placeholder="Select date range"
        type="text"
        readonly
        @click="openModal('calendar')"
        :value="displayDate(bookingParams)">
      <div v-if="datesChangedInfo" class="booking-menu__inputs__hint">{{ $t('bookingModal.datesChanged') }}</div>
    </div>

    <div class="col-12 col-md-3">
      <label for="guests">
        {{ $t('bookingModal.guests') }}
        <i class="icon-caret-down"></i>
      </label>
      <input
        id="guests"
        placeholder="Select guests"
        type="text"
        readonly
        @click="openModal('guests')"
        :value="displayGuests(bookingParams, $t, property?.maxChildAge === 0)">
    </div>

    <div class="col-12 col-md-3">
      <BookingAccessCode :bookingParams="bookingParams" :isAccessCodeValid="isAccessCodeValid" :accessCodeRemovedInfo="accessCodeRemovedInfo" />
    </div>

    <div class="col-12 col-md-3 booking-menu__update">
      <button
        class="button"
        :class="bookingParamsChanged ? 'button--primary' : 'button--outline'"
        @click="checkAccessCodeAndUpdate()">
        {{ $t('bookingModal.updateBtn') }}
      </button>
    </div>
  </div>
</div>

<BookingModal
  :isOpen="isModalOpen"
  :level="level"
  :property="property"
  :propertyIds="propertyIds"
  :bookingParams="bookingParams"
  :bookingParamsChanged="bookingParamsChanged"
  :datesChangedInfo="datesChangedInfo"
  :availabilityCalendar="availabilityCalendar"
  :isAccessCodeValid="isAccessCodeValid"
  @updateBookingParams="checkAccessCodeAndUpdate()"
  @changeLevel="e => level = e"
  @closeModal="closeModal" />
  `,
  props: {
    startDate: Object,
    nights: Number,
    adults: Number,
    children: Array,
    property: Object,
    propertyIds: Array,
    accessCode: String,
  },
  emits: ['updateBookingParams', 'error'],
  setup (props, { emit }) {
    const bookingParams = Vue.reactive({
      startDate: props.startDate,
      nights: props.nights,
      adults: props.adults,
      children: props.children,
      accessCode: props.accessCode,
    })
    const { propertyIds } = Vue.toRefs(props)
    const unitId = Vue.ref(null)
    const availabilityCalendar = Vue.reactive(new AvailabilityCalendar())
    const isModalOpen = Vue.ref(false)
    const level = Vue.ref('inputs')
    const isAccessCodeValid = Vue.ref(true)
    const bookingParamsChanged = Vue.ref(false)
    const datesChangedInfo = Vue.ref(false)
    const accessCodeRemovedInfo = Vue.ref(false)

    Vue.watch([propertyIds, unitId], async ([propertyIds, unitId]) => {
      try {
        await availabilityCalendar.loadAvailability({ propertyIds, unitId, accessCode: bookingParams.accessCode })
        checkDateAvailability()
      } catch (e) {
        setErrorAndStopLoading(e)
      }
    })

    Vue.watch(bookingParams, () => {
      bookingParamsChanged.value = true
      isAccessCodeValid.value = true
    })

    async function checkAccessCodeAndUpdate () {
      accessCodeRemovedInfo.value = false
      isAccessCodeValid.value = true
      isAccessCodeValid.value = await checkAccessCode()

      if (isAccessCodeValid.value) {
        bookingParamsChanged.value = false
        datesChangedInfo.value = false

        emit('updateBookingParams', bookingParams)
        closeModal()
      }
    }

    async function checkAccessCode () {
      bookingParams.accessCode = bookingParams.accessCode.trim()
      try {
        if (bookingParams.accessCode !== '') {
          const response = await fetch(`${AZURE_URI}CheckAccessCode?accessCode=${encodeURIComponent(bookingParams.accessCode)}&locale=${LOCALE}`)
          if (!response.ok) {
            throw new Error('Error checking access code.')
          }
          const ids = await response.json()
          return (IS_BOOKING ? ids.filter(id => id === propertyIds.value[0]) : ids).length > 0
        }
      } catch (err) {
        setErrorAndStopLoading(err)
      }

      return true
    }

    function openContainer () {
      isModalOpen.value = true
    }

    function openModal (l, options) {
      unitId.value = options?.unitId ? options.unitId : null
      isModalOpen.value = true
      level.value = l
    }

    function closeModal () {
      isModalOpen.value = false
    }

    function checkDateAvailability () {
      if (availabilityCalendar.isDateRangeAvailable(bookingParams.startDate, bookingParams.nights)) return
      const firstAvailableDate = availabilityCalendar.getFirstAvailableDateRange(bookingParams.nights)
      if (firstAvailableDate === null) return

      bookingParams.startDate = firstAvailableDate
      datesChangedInfo.value = true
    }

    Vue.onMounted(async () => {
      if (!(await checkAccessCode())) {
        bookingParams.accessCode = ''
        accessCodeRemovedInfo.value = true
      }
    })

    return {
      bookingParams,
      availabilityCalendar,
      isModalOpen,
      level,
      displayDate,
      displayGuests,
      openContainer,
      openModal,
      closeModal,
      isAccessCodeValid,
      bookingParamsChanged,
      datesChangedInfo,
      accessCodeRemovedInfo,
      checkAccessCodeAndUpdate,
    }
  },
}
