/* global Vue */
import * as licenseKey from '@params'

export default {
  name: 'UnitImage',
  components: {},
  template: `
  <div class="unit-card__gallery lightgallery" ref="gallery">
    <img :src="unit.image" :alt="unit.name" class="gallery-button-img" />

    <template v-if="unit.gallery">
      <button class="view-gallery-button gallery-button view-gallery-button--right" title="View gallery">
        <span>{{ $t('stepOne.viewGallery') }}</span>
        <i class="icon-gallery"></i>
      </button>

      <div class="unit-card__gallery gallery-images">
        <a v-for="image in unit.gallery.items" class="galleryImage" title="" :href="optimizeImage(image)"></a>
      </div>
    </template>
  </div>
`,
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const gallery = Vue.ref(null)

    Vue.onMounted(() => {
      const imagesWrapper = gallery.value.querySelector('.gallery-images')
      const images = gallery.value.querySelectorAll('.gallery-images a')
      const buttons = gallery.value.querySelectorAll('.gallery-button')
      const imagesAsButtons = gallery.value.querySelectorAll('.gallery-button-img')

      function openGallery (index = 0) {
        if (images.length > 0) {
          images[index].click()
        }
      }

      imagesAsButtons?.forEach((image, i) => {
        image.addEventListener('click', () => openGallery(i), { passive: true })
      })

      if (buttons) {
        buttons.forEach(button => {
          const index = button.dataset.index || 0
          button.addEventListener('click', () => openGallery(index), { passive: true })
          if (imagesWrapper) {
            window.lightGallery(imagesWrapper, {
              licenseKey,
              download: false,
              mobileSettings: { showCloseIcon: true },
            })
          }
        })
      }
    })

    function optimizeImage(image) {
      return image
    }

    return {
      gallery,
      optimizeImage
    }
  }
}
