const messagesCommon = {
  en: {
    loading: '', // just a placeholder for empty
    call: 'Call',
    cancel: 'Cancel',
    join: 'Sign in',
    country: 'Country',
    search: 'Search',
    close: 'Close',
    next: 'Next',
    previous: 'Previous',
    select: 'Select',
    eur: '€',
    homepage: 'Home page',
    bookNow: 'Book Now',
    discoverMore: 'Discover more',
    until: 'until',
    learnMore: 'Learn more',
    localeSwitch: {
      buttonTitle: 'Switch Locale',
      title: 'Preferences',
      description: 'Please select your preferred language and currency.',
      selectLanguage: 'Select Language',
      selectCurrency: 'Select Currency',
      footer: 'All prices in currencies other than euros are approximate.',
      button: 'Update preferences',
    },
    accessCode: {
      label: 'Promo Code',
      placeholder: 'Enter Promo Code',
      confirm: 'Confirm',
      remove: 'Remove',
      invalidAccessCode: 'The entered promo code is not valid.',
      validAccessCode: 'The entered promo code is valid.',
    },
    bookingModal: {
      date: 'Arrival & departure',
      guests: 'Guests',
      accessCode: 'Promo code',
      howMany: 'Select number of guests',
      adults: 'Adults',
      noChildren: 'Children',
      selectAge: 'Child age',
      selectBtn: 'Select',
      updateBtn: 'Update search',
      closeBtn: 'Close',
      openBtn: 'Open',
      invalidAccessCode: 'Promo code not valid',
      children: 'children',
      age: 'Child age',
      note: 'Accommodation price is shown per unit and is subject to change depending on the number of persons and additional services.',
      datesChanged: 'Dates changed based on availability.',
      accessCodeRemoved: 'Promo code was removed because it is not valid for this property.',
    },
    loyaltyBanner: {
      title: 'With gratitude, from your first stay',
      description: 'Become a MaiStar Rewards Club member and save up to 20%. It’s easy to join using your personal information while booking your stay.',
      button: 'Become a member',
      membershipDiscount: 'membership discount',
      becomeMember: 'Become a member',
      signIn: 'Sign in',
    },
    loyaltyInvite: {
      title: 'Join MaiStar Rewards Club',
      maiStarClub: 'MaiStar Rewards Club',
      becomeMessage: 'Become a MaiStar Rewards Club member and save up to 20%.',
      confirmedMembership: 'I want to join the MaiStar Rewards Club. I have read and accept the {termsAndConditions} and the {privacyNotice}.',
      termsAndConditions: 'Terms and Conditions of the Loyalty Programme',
      privacyNotice: 'Privacy notice',
      unconfirmedMembership: 'I don’t want to become a member',
      alreadyMember: 'Already a member?',
      signIn: 'Sign in',
      unconfirmedMembershipNotice: 'If you don’t want to proceed with joining loyalty programme - please [logout].',
      unconfirmedMembershipNoticeLogout: 'logout',
    },
    unitDemand: {
      availabilityError: 'Availability could not be determined',
      contactText: 'Sorry, there are no available units for the selected dates.',
      contactButton: 'Contact us',
      modifySearch: 'Modify search',
    },
    authStatus: {
      dashboard: 'Overview',
      myBookings: 'My bookings',
      pointsActivity: 'Points activity',
      MaiStarRewards: 'MaiStar rewards',
      myProfile: 'My profile',
      completeRegistration: 'Complete registration',
      openMenu: 'Open menu',
      closeMenu: 'Close menu',
      login: 'Login',
      logout: 'Logout',
    },
    loyaltyProfile: {
      profileLocales: {
        l1050: 'Croatian',
        l1040: 'Italian',
        l1033: 'English',
        l1031: 'German',
        l1060: 'Slovenian',
        l1045: 'Polish',
        l2067: 'Dutch',
      },
      profileTitle: {
        mr: 'Mr.',
        mrs: 'Mrs.',
      },
      identity: 'Identity',
      title: 'Title',
      firstName: 'First name',
      lastName: 'Last name',
      dob: 'Date of birth',
      locale: 'Language',
      contact: 'Contact',
      email: 'Email',
      address: 'Address',
      postalCode: 'Postal Code',
      city: 'City',
      phoneNumber: 'Phone Number',
      updateSuccess: 'You successfully updated your profile.',
    },
    formValidation: {
      required: '*This field is required.',
      email: 'This field must be a valid email.',
      min: 'This field must be at least',
      maxPoints: 'The selected amount is not available.',
      eq: 'This field must have',
      digits: 'digits',
      characters: 'characters',
      size: 'Maximum file size is',
      phone: 'Please enter a valid phone number, with country code with prefix (+ or 00) and only numbers and spaces, no other separators allowed. Use form like +49 2 545 8654.',
      reqAtLeastOne: 'You need to select at least one option.',
      number: 'This should be a number.',
      reqNumber: 'You need to enter a valid number.',
      reqFile: 'You need to select a file to upload.',
      notVerifiedMail: 'Please check and verify your email again.',
      ccard: 'The card number you have entered is invalid.',
      cvv: 'Please enter a valid CVV number. Should be 3 digits.',
      cvv34: 'Please enter a valid CVV number. Should be 3 or 4 digits.',
      onePositive: 'One of the fields must be a positive number',
      birthdate: 'The entered birthdate is not valid.',
    },
    notification: {
      success: 'Successfully submitted.',
      error: 'Error while submitting.',
      moreInfo: 'More info',
    },
    fatalError: {
      title: 'We\'re sorry, but something went wrong.',
      message: 'Please go back to home page and try again.',
      button: 'Back To Homepage',
      code: {
        propertyIdMissing: 'You arrived to this page without selected property.',
        unitOrRateMissing: 'You arrived to this page without selecting unit or rate.',
        phobsError10001: 'Request limit to external booking service reached',
        phobsError5008: 'There is no availability for the selected unit. Please try again with a different unit.',
        validationError: 'Error validating your entered data. Please review your personal details and try again.',
      },
    },
    myProfile: {
      title: 'My Profile',
      changeEmailText: 'To change your e-mail, please contact our',
      changeEmailCC: 'Customer Care',
      newsletter: 'Keep me updated with news, offers and exclusive member deals.',
      registrationPrivacy1: 'By clicking the “Register“ button below, you accept membership in the MaiStar loyalty programme and our {termsAndConditions}. You confirm that you have read and understood the {privacyNotice} on how we process your data in the context of your membership. You accept that Maistra will process personal data, including your name and contact information, date of birth, preferences, and how and when you use our services. You understand that we will use this data to enable you to participate in our loyalty programme, create special offers, provide you with a personalised experience based on our previous interactions and your stated preferences, and keep you informed about our products and services according to your interests.',
      registrationPrivacy2: 'You understand that you have the right to request access to or transfer your data at any time, erase your data, correct it and object to the processing of personal data by contacting the data protection officer at {officerEmail}. You have the right to lodge a complaint to the local supervisory authority.',
      privacyNotice: {
        text: 'Privacy Notice',
        href: '/loyalty-privacy-notice',
      },
      termsAndConditions: {
        text: 'Terms and Conditions',
        href: '/loyalty-terms-and-conditions',
      },
      privacyProcedure: 'We do not share your personal data with third parties for the purpose of advertising their services. We will not sell your personal data to third parties. Please read our {privacyPolicy} to find out more about data privacy procedures.',
      privacyPolicy: 'Privacy Notice',
      requiredFields: 'These fields are required.',
      dataProtection: '<p>We respect your privacy and are committed to protecting your personal data. We collect different types of personal data about you, depending on your relationship with us and the services you use. In any case, we collect and process your personal data for legitimate purposes and on a valid legal basis.</p><p>For more information about the protection of your personal data, please see our <a href="#">Privacy Notice</a>.</p>',
    },
  },
  de: {
    loading: '', // Platzhalter
    call: 'Anruf',
    cancel: 'Stornieren',
    join: 'Beitreten',
    country: 'Land',
    search: 'Suche',
    close: 'Schließen',
    next: 'Nächste',
    previous: 'Vorige',
    select: 'Auswählen',
    eur: '€',
    homepage: 'Homepage',
    bookNow: 'Jetzt buchen',
    discoverMore: 'Mehr entdecken',
    until: 'bis',
    learnMore: 'Mehr entdecken',
    localeSwitch: {
      buttonTitle: 'Gebietsschema schalten',
      title: 'Einstellungen',
      description: 'Bitte wählen Sie Ihre bevorzugte Sprache und Währung aus',
      selectLanguage: 'Sprache auswählen',
      selectCurrency: 'Währung auswählen',
      footer: 'Preisangaben in anderen währungen als euro sind lediglich richtwerte.',
      button: 'Einstellungen aktualisieren',
    },
    accessCode: {
      label: 'Gutscheincode',
      placeholder: 'Gutscheincode',
      confirm: 'Bestätigen',
      remove: 'Entfernen',
      invalidAccessCode: 'Der eingegebene Gutscheincode ist ungültig.',
      validAccessCode: 'Der eingegebene Gutscheincode ist gültig.',
    },
    bookingModal: {
      date: 'Ankunft und Abfahrt',
      guests: 'Gäste',
      accessCode: 'Gutscheincode',
      howMany: 'Wie viele Gäste werden übernachten?',
      adults: 'Erwachsene',
      noChildren: 'Kinder',
      selectAge: 'Kind',
      selectBtn: 'Auswählen',
      updateBtn: 'Suche aktualisieren',
      closeBtn: 'Schließen',
      openBtn: 'Öffnen',
      invalidAccessCode: 'Gutscheincode ist ungültig',
      children: 'Kinder',
      age: 'Alter des Kindes',
      note: 'Die Preise für die Unterkunft werden pro Einheit angegeben und können sich abhängig von der Personenzahl und den Zusatzleistungen ändern.',
      datesChanged: 'Die Termine wurden aufgrund der Verfügbarkeit geändert.',
      accessCodeRemoved: 'Der Promo-Code wurde entfernt, da er für diese Unterkunft nicht gültig ist.',
    },
    loyaltyBanner: {
      title: 'Als kleines Dankeschön für Ihren ersten Aufenthalt',
      description: 'Werden Sie Mitglied im MaiStar Rewards Club und sparen Sie bis zu 20 %. Sie können ihm mit Ihren persönlichen Daten ganz einfach beitreten, während Sie Ihren Aufenthalt buchen.',
      button: 'Mitglied werden',
      membershipDiscount: 'Mitgliederrabatt',
      becomeMember: 'Mitglied werden',
      signIn: 'Anmelden',
    },
    loyaltyInvite: {
      title: 'Dem MaiStar Rewards Club beitreten',
      maiStarClub: 'MaiStar Rewards Club',
      becomeMessage: 'Werden Sie Mitglied im MaiStar Rewards Club und sparen Sie bis zu 20 %.',
      confirmedMembership: 'Ich möchte dem MaiStar Rewards Club beitreten. Ich habe die {termsAndConditions} und die {privacyNotice} gelesen und akzeptiere sie',
      termsAndConditions: 'Allgemeine Geschäftsbedingungen des Treueprogramms',
      privacyNotice: 'Datenschutzerklärung',
      unconfirmedMembership: 'Ich möchte kein Mitglied werden',
      alreadyMember: 'Sind Sie schon ein Mitglied?',
      signIn: 'Anmelden',
      unconfirmedMembershipNotice: 'Wenn Sie dem Treueprogramm nicht beitreten möchten, [logout].',
      unconfirmedMembershipNoticeLogout: 'melden Sie sich bitte ab',
    },
    unitDemand: {
      availabilityError: 'Die Verfügbarkeit konnte nicht ermittelt werden',
      contactText: 'Leider sind keine Einheiten für den gewünschten Zeitraum verfügbar.',
      contactButton: 'Kontaktieren Sie uns',
      modifySearch: 'Suche bearbeiten',
    },
    authStatus: {
      dashboard: 'Dashboard',
      myBookings: 'Meine Buchungen',
      pointsActivity: 'Aktivierte Punkte',
      MaiStarRewards: 'MaiStar Preise',
      myProfile: 'Mein Profil',
      completeRegistration: 'Registrierung abschließen',
      openMenu: 'Menü öffnen',
      closeMenu: 'Menü schließen',
      login: 'Login',
      logout: 'Logout',
    },
    loyaltyProfile: {
      profileLocales: {
        l1050: 'Kroatisch',
        l1040: 'Italienisch',
        l1033: 'Englisch',
        l1031: 'Deutsch',
        l1060: 'Slowenisch',
        l1045: 'Polnisch',
        l2067: 'Niederländisch',
      },
      profileTitle: {
        mr: 'Herr',
        mrs: 'Frau',
      },
      identity: 'Persönliche Daten',
      title: 'Anrede',
      firstName: 'Vorname',
      lastName: 'Nachname',
      dob: 'Geburtsdatum',
      locale: 'Sprache',
      contact: 'Kontakt',
      email: 'E-Mail',
      address: 'Adresse',
      postalCode: 'Postleitzahl',
      city: 'Stadt',
      phoneNumber: 'Telefonnummer',
      updateSuccess: 'Sie haben Ihr Profil erfolgreich aktualisiert.',
    },
    formValidation: {
      required: '*Dieses Feld ist erforderlich.',
      email: 'In dieses Feld muss eine gültige E-Mail-Adresse eingegeben werden.',
      min: 'Dieses Feld muss mindestens enthalten',
      maxPoints: 'Der ausgewählte Betrag ist nicht verfügbar.',
      eq: 'Dieses Feld muss enthalten',
      digits: 'Ziffern',
      characters: 'Zeichen',
      size: 'Die maximale Dateigröße ist',
      phone: 'Geben Sie eine gültige Telefonnummer, mit vorangestellter Landesvorwahl (+ oder 00) ein. Sie können nur Zahlen und Leerzeichen eingeben, andere Trennzeichen sind nicht erlaubt. Geben Sie eine Nummer im Format wie folgt ein: +49 2 545 8654',
      reqAtLeastOne: 'Sie müssen mindestens eine Option auswählen.',
      number: 'Das sollte eine Nummer sein.',
      reqNumber: 'Sie müssen eine gültige Nummer eingeben.',
      reqFile: 'Sie müssen eine Datei zum Hochladen auswählen.',
      notVerifiedMail: 'Bitte prüfen und bestätigen Sie Ihre E-Mail.',
      ccard: 'Die eingegebene Kartennummer ist ungültig.',
      cvv: 'Bitte geben Sie eine gültige CVV-Nummer ein. Die Nummer muss 3 Ziffern enthalten.',
      cvv34: 'Bitte geben Sie eine gültige CVV-Nummer ein. Die Nummer muss 3 oder 4 Ziffern enthalten.',
      onePositive: 'Eines der Felder muss eine positive Zahl sein',
      birthdate: 'Die eingegebene Geburtsdatum ist ungültig.',
    },
    notification: {
      success: 'Erfolgreich versendet.',
      error: 'Beim Senden ist ein Fehler aufgetreten.',
      moreInfo: 'Mehr Informationen',
    },
    fatalError: {
      title: 'Entschuldigung, ein Fehler ist aufgetreten.',
      message: 'Gehen Sie zurück zur Startseite und versuchen Sie es erneut.',
      button: 'Zurück zur Startseite',
      code: {
        propertyIdMissing: 'Sie sind auf diese Seite gelangt, ohne ein Objekt auszuwählen.',
        unitOrRateMissing: 'Sie sind auf dieser Seite angekommen, ohne eine Einheit oder einen Preis auszuwählen.',
        phobsError10001: 'Sie haben das Anforderungslimit für den externen Buchungsservice erreicht',
        phobsError5008: 'Die ausgewählte Einheit ist nicht verfügbar. Bitte versuchen Sie es erneut mit einer anderen Einheit.',
        validationError: 'Fehler beim Validieren Ihrer eingegebenen Daten. Bitte überprüfen Sie Ihre personenbezogenen Daten und versuchen Sie es erneut.',
      },
    },
    myProfile: {
      title: 'Mein Profil',
      changeEmailText: 'Um Ihre E-Mail zu ändern, wenden Sie sich bitte an',
      changeEmailCC: 'Kundendienst',
      newsletter: 'Informieren Sie mich über Neuigkeiten, Angebote und exklusive Mitgliederangebote.',
      registrationPrivacy1: 'Indem Sie unten auf "Registrieren" klicken, akzeptieren Sie die Mitgliedschaft im MaiStar Treueprogramm und unsere {termsAndConditions}. Sie bestätigen, dass Sie die {privacyNotice} zur Verarbeitung Ihrer Daten im Rahmen Ihrer Mitgliedschaft gelesen und verstanden haben. Sie erklären sich damit einverstanden, dass Maistra personenbezogene Daten, einschließlich Ihres Namens und Ihrer Kontaktdaten, Ihres Geburtsdatums, Ihrer Präferenzen und Informationen darüber, wie und wann Sie unsere Dienste nutzen, verarbeitet. Sie sind damit einverstanden, dass wir diese Daten verwenden, um Ihnen die Teilnahme an unserem Treueprogramm zu ermöglichen, spezielle Angebote zu erstellen und Ihnen ein personalisiertes Erlebnis zu bieten, das auf unseren früheren Interaktionen und Ihren angegebenen Präferenzen basiert, und um Sie entsprechend Ihren Interessen über unsere Produkte und Dienstleistungen zu informieren. Sie erkennen an, dass wir Informationen über Sie verwenden können, um zusammengefasste analytische Berichte darüber zu erstellen, wie unsere Dienste genutzt werden, sowie für Marktforschungszwecke.',
      registrationPrivacy2: 'Sie wissen, dass Sie das Recht haben, jederzeit Zugang zu Ihren Daten zu verlangen oder diese zu übertragen, Ihre Daten zu löschen, zu berichtigen oder der Verarbeitung personenbezogener Daten zu widersprechen, indem Sie sich an den Datenschutzbeauftragten an die E-Mail-Adresse {officerEmail} wenden. Sie haben das Recht, eine Beschwerde bei der örtlichen Aufsichtsbehörde einzureichen.',
      privacyNotice: {
        text: 'Datenschutzerklärung',
        href: '/loyalty-datenschutzerklaerung',
      },
      termsAndConditions: {
        text: 'Allgemeine Geschäftsbedingungen',
        href: '/loyalty-allgemeine-geschaeftsbedingungen',
      },
      privacyProcedure: 'Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, um deren Dienste zu bewerben. Wir werden Ihre personenbezogenen Daten nicht an Dritte verkaufen. Bitte lesen Sie unsere {privacyPlocy}, um mehr über die Datenschutzverfahren zu erfahren.',
      privacyPolicy: 'Datenschutzerklärung',
      requiredFields: 'Diese Felder sind Pflichtfelder.',
      dataProtection: '<p>Wir respektieren Ihre Privatsphäre und verpflichten uns, Ihre personenbezogenen Daten zu schützen. Wir erheben verschiedene Arten von personenbezogenen Daten über Sie, abhängig von Ihrer Beziehung zu uns und den von Ihnen genutzten Leistungen. Wir erheben und verarbeiten Ihre personenbezogenen Daten für legitime Zwecke und auf einer gültigen Rechtsgrundlage.</p><p>Weitere Informationen zum Schutz Ihrer personenbezogenen Daten finden Sie in unserer <a href="#"> Datenschutzerklärung</a>.</p>',
    },
  },
  hr: {
    loading: '', // zamjenski znak za prazno polje
    call: 'Nazovi',
    cancel: 'Otkaži',
    join: 'Pridruži se',
    country: 'Država',
    search: 'Pretraži',
    close: 'Zatvori',
    next: 'Sljedeće',
    previous: 'Prethodno',
    select: 'Odaberi',
    eur: '€',
    homepage: 'Home page',
    bookNow: 'Rezerviraj sada',
    discoverMore: 'Otkrijte više',
    until: 'do',
    learnMore: 'Otkrijte više',
    localeSwitch: {
      buttonTitle: 'Prebacite lokalitet',
      title: 'Preferencije',
      description: 'Odaberite jezik i valutu koji vam odgovaraju',
      selectLanguage: 'Odabir jezika',
      selectCurrency: 'Odabir valute',
      footer: 'Cijene izražene u svim valutama, osim u eurima su informativnog karaktera.',
      button: 'Ažuriraj preferencije',
    },
    accessCode: {
      label: 'Promotivni kod',
      placeholder: 'Unesite promotivni kod',
      confirm: 'Potvrdi',
      remove: 'Ukloni',
      invalidAccessCode: 'Uneseni promotivni kod nije valjan.',
      validAccessCode: 'Uneseni promotivni kod je valjan.',
    },
    bookingModal: {
      date: 'Dolazak i odlazak',
      guests: 'Gosti',
      accessCode: 'Promotivni kod',
      howMany: 'Koliko će gostiju boraviti?',
      adults: 'Odrasli',
      noChildren: 'Djeca',
      selectAge: 'Dijete',
      selectBtn: 'Odaberi',
      updateBtn: 'Ažuriraj pretragu',
      closeBtn: 'Zatvori',
      openBtn: 'Otvori',
      invalidAccessCode: 'Promotivni kod nije valjan',
      children: 'djeca',
      age: 'Dob djeteta',
      note: 'Cijene smještaja iskazane su po jedinici te su podložne promjeni ovisno o broju osoba i dodatnim uslugama.',
      datesChanged: 'Datumi su promijenjeni zbog dostupnosti.',
      accessCodeRemoved: 'Promotivni kod je uklonjen jer nije važeći za ovaj objekt.',
    },
    loyaltyBanner: {
      title: 'Zahvalni smo od vašeg prvog boravka',
      description: 'Postanite članom programa MaiStar Rewards Club i uštedite do 20 %. Lako se možete pridružiti s pomoću osobnih podataka tijekom rezerviranja smještaja.',
      button: 'Postanite članom',
      membershipDiscount: 'popust za članove',
      becomeMember: 'Postanite članom',
      signIn: 'Prijavite se',
    },
    loyaltyInvite: {
      title: 'Pridružite se programu MaiStar Rewards Club',
      maiStarClub: 'MaiStar Rewards Club',
      becomeMessage: 'Postanite članom programa MaiStar Rewards Club i uštedite do 20 %.',
      confirmedMembership: 'Želim se pridružiti programu MaiStar Rewards Club. Pročitao/la sam i prihvaćam {termsAndConditions} te {privacyNotice}.',
      termsAndConditions: 'Odredbe i uvjeti Programa lojalnosti',
      privacyNotice: 'Obavijest o privatnosti',
      unconfirmedMembership: 'Ne želim postati članom',
      alreadyMember: 'Već ste član?',
      signIn: 'Prijavite se',
      unconfirmedMembershipNotice: 'Ukoliko ne želite nastaviti s pridruživanjem programu vjernosti - molimo da se [logout].',
      unconfirmedMembershipNoticeLogout: 'odjavite',
    },
    unitDemand: {
      availabilityError: 'Raspoloživost nije bilo moguće utvrditi',
      contactText: 'Nažalost nema raspoloživih jedinica u traženom periodu.',
      contactButton: 'Obratite nam se',
      modifySearch: 'Izmijeni pretragu',
    },
    authStatus: {
      dashboard: 'Prikaz',
      myBookings: 'Moje rezervacije',
      pointsActivity: 'Aktivirani bodovi',
      MaiStarRewards: 'MaiStar Rewards',
      myProfile: 'Moj profil',
      completeRegistration: 'Dovrši registraciju',
      openMenu: 'Otvori izbornik',
      closeMenu: 'Zatvori izbornik',
      login: 'Prijava',
      logout: 'Odjava',
    },
    loyaltyProfile: {
      profileLocales: {
        l1050: 'Hrvatski',
        l1040: 'Talijanski',
        l1033: 'Engleski',
        l1031: 'Njemački',
        l1060: 'Slovenski',
        l1045: 'Poljski',
        l2067: 'Nizozemski',
      },
      profileTitle: {
        mr: 'Gospodin',
        mrs: 'Gospođa',
      },
      identity: 'Osobni podaci',
      title: 'Titula',
      firstName: 'Ime',
      lastName: 'Prezime',
      dob: 'Datum rođenja',
      locale: 'Jezik',
      contact: 'Kontakt',
      email: 'E-pošta',
      address: 'Adresa',
      postalCode: 'Poštanski broj',
      city: 'Grad',
      phoneNumber: 'Broj telefona',
      updateSuccess: 'Uspješno ste ažurirali svoj profil.',
    },
    formValidation: {
      required: '*Ovo polje je obavezno.',
      email: 'U ovo polje mora biti unesena valjana e-pošta.',
      min: 'Ovo polje mora sadržavati najmanje',
      max: 'Ovo polje mora sadržavati najviše',
      maxPoints: 'Odabrani iznos nije dostupan.',
      eq: 'Ovo polje mora sadržavati',
      digits: 'znamenka',
      characters: 'znakovi',
      size: 'Maksimalna veličina datoteke je',
      phone: 'Unesite valjani broj telefona s pozivnim brojem s prefiksom (+ ili 00). Možete unijeti samo brojeve i razmake, drugi znakovi za odvajanje nisu dopušteni. Unesite broj u formatu poput +49 2 545 8654.',
      reqAtLeastOne: 'Morate odabrati najmanje jednu opciju.',
      number: 'Ovo bi trebao biti broj.',
      reqNumber: 'Trebate unijeti valjani broj.',
      reqFile: 'Morate odabrati datoteku za prijenos.',
      notVerifiedMail: 'Provjerite i ponovno potvrdite svoju e-poštu.',
      ccard: 'Broj kartice koji ste unijeli nije važeći.',
      cvv: 'Unesite važeći CVV broj. Broj treba sadržavati 3 znamenke.',
      cvv34: 'Unesite važeći CVV broj. Broj treba sadržavati 3 ili 4 znamenke.',
      onePositive: 'Jedno od polja mora biti pozitivan broj',
      birthdate: 'Uneseni datum rođenja nije ispravan.',
    },
    notification: {
      success: 'Uspješno poslano.',
      error: 'Došlo je do pogreške prilikom slanja.',
      moreInfo: 'Više informacija',
    },
    fatalError: {
      title: 'Žao nam je, došlo je do pogreške.',
      message: 'Vratite se natrag na početnu stranicu i pokušajte ponovno.',
      button: 'Natrag na početnu stranicu',
      code: {
        propertyIdMissing: 'Došli ste na ovu stranicu bez da ste odabrali objekt.',
        unitOrRateMissing: 'Došli ste na ovu stranicu bez odabira jedinice ili cijene.',
        phobsError10001: 'Dosegnuli ste ograničenje zahtjeva za vanjsku uslugu rezerviranja',
        phobsError5008: 'Odabrana jedinica nije dostupna. Pokušajte ponovno s drugom jedinicom.',
        validationError: 'Došlo je do pogreške pri potvrđivanju unesenih podataka. Pregledajte svoje osobne podatke i pokušajte ponovno.',
      },
    },
    myProfile: {
      title: 'Moj profil',
      changeEmailText: 'Da biste promijenili svoju e-poštu, obratite se',
      changeEmailCC: 'Služba za korisnike',
      newsletter: 'Obavještavajte me o novostima, ponudama i ekskluzivnim popustima za članove.',
      registrationPrivacy1: 'Klikom na tipku „Registracija” u nastavku izražavate svoj pristanak na članstvo u programu vjernosti MaiStar te također prihvaćate naše {termsAndConditions}. Potvrđujete da ste pročitali i razumjeli {privacyNotice} o načinu obrade vaših podataka u okviru vašeg članstva. Suglasni ste s time da Maistra obrađuje osobne podatke uključujući vaše ime i podatke za kontakt, datum rođenja, sklonosti te podatke o tome kako i kada se koristite našim uslugama. Razumijete da ćemo navedene podatke upotrebljavati kako bismo vam omogućili sudjelovanje u našem programu vjernosti, za izradu posebnih ponuda i pružanje personaliziranog iskustva, sve na temelju naših ranijih interakcija i sklonosti koje sami navedete, te kako bismo vas informirali o našim proizvodima i uslugama u skladu s vašim interesima. Suglasni ste s time da podatke o vama možemo upotrebljavati za izradu skupnih analitičkih izvješća o načinu na koji se korisnici koriste našim uslugama, kao i za potrebe istraživanja tržišta.',
      registrationPrivacy2: 'Razumijete da u svakom trenutku imate pravo zatražiti pristup svojim osobnim podacima, kao i pravo na prenosivost podataka, pravo na brisanje („pravo na zaborav”), pravo na ispravak te pravo prigovora na obradu osobnih podataka, na način da se obratite službeniku za zaštitu podataka putem adrese e-pošte {officerEmail}. Imate pravo na podnošenje pritužbe lokalnom nadzornom tijelu.',
      privacyNotice: {
        text: 'Obavijest o privatnosti',
        href: '/loyalty-obavijest-o-privatnosti',
      },
      termsAndConditions: {
        text: 'Uvjete programa vjernosti',
        href: '/loyalty-opci-uvjeti',
      },
      privacyProcedure: 'Ne dijelimo vaše osobne podatke s trećom stranom radi oglašavanja njihovih usluga. Vaše osobne podatke nećemo prodati trećoj strani. Više informacija o postupanju u vidu privatnosti pronađite u našoj {privacyPolicy}.',
      privacyPolicy: 'Obavijest o privatnosti',
      requiredFields: 'Ova su polja obavezna.',
      dataProtection: '<p>Poštujemo vašu privatnost i obvezujemo se na zaštitu vaših osobnih podataka. Prikupljamo različite vrste vaših osobnih podataka ovisno o odnosu koji imate s nama i uslugama koje upotrebljavate. U svakom slučaju vaše osobne podatke prikupljamo i obrađujemo sa svrhom i prema zakonu.</p><p>Više informacija o zaštiti osobnih podataka potražite u našoj <a href="#">Izjavi o privatnosti</a>.</p>',
    },
  },
  it: {
    loading: 'Caricamento in corso...',
    call: 'Chiamata',
    cancel: 'Annulla',
    join: 'Unisciti',
    country: 'Paese',
    search: 'Ricerca',
    close: 'Chiudi',
    next: 'Successivo',
    previous: 'Precedente',
    select: 'Seleziona',
    eur: '€',
    homepage: 'Home page',
    bookNow: 'Prenota ora',
    discoverMore: 'Scopri di più',
    until: 'fino a',
    learnMore: 'Maggiori informazioni',
    localeSwitch: {
      buttonTitle: 'switchLocale',
      title: 'Preferenze',
      description: 'Si prega di selezionare la lingua e la valuta preferite',
      selectLanguage: 'Seleziona lingua',
      selectCurrency: 'Seleziona valuta',
      footer: 'I prezzi indicati in tutte le altre valute tranne euro sono informativi.',
      button: 'Aggiorna preferenze',
    },
    accessCode: {
      label: "Codice d'accesso",
      placeholder: 'Inserisci il codice promozionale',
      confirm: 'Conferma',
      remove: 'Rimuovi',
      invalidAccessCode: 'Il codice promozionale inserito non è valido.',
      validAccessCode: 'Il codice promozionale inserito è valido.',
    },
    bookingModal: {
      date: 'Arrivo e partenza',
      guests: 'Ospiti',
      accessCode: 'Codice promozionale',
      howMany: 'Quanti ospiti vi alloggeranno?',
      adults: 'Adulto',
      noChildren: 'Bambini',
      selectAge: 'Bambino',
      selectBtn: 'Seleziona',
      updateBtn: 'Aggiorna ricerca',
      closeBtn: 'Chiudi',
      openBtn: 'Apri',
      invalidAccessCode: 'Codice promozionale non valido',
      children: 'bambini',
      age: 'Età del bambino',
      note: 'I prezzi degli alloggi si intendono per singola unità e sono soggetti a variazioni in base al numero di persone e servizi supplementari.',
      datesChanged: 'Le date sono cambiate in base alla disponibilità.',
      accessCodeRemoved: 'Il codice promozionale è stato rimosso perché non valido per questa struttura.',
    },
    loyaltyBanner: {
      title: 'Con gratitudine, dal Suo primo soggiorno',
      description: 'Diventi affiliato del programma MaiStar Rewards Club e risparmi fino al 20%. Si può unire a noi, semplicemente, utilizzando i Suoi dati personali, mentre prenota il Suo soggiorno.',
      button: 'Diventa affiliato’',
      membershipDiscount: 'sconto affiliato’',
      becomeMember: 'Diventa affiliato’',
      signIn: 'Accedi',
    },
    loyaltyInvite: {
      title: 'Si unisca al programma MaiStar Rewards Club’',
      maiStarClub: 'MaiStar Rewards Club',
      becomeMessage: 'Diventi affiliato del programma MaiStar Rewards Club e risparmi fino al 20%.',
      confirmedMembership: 'Desidero unirmi al programma MaiStar Rewards Club. Ho letto e accetto i {termsAndConditions} e {privacyNotice}.',
      termsAndConditions: 'Termini e condizioni del programma fedeltà',
      privacyNotice: 'l\'Informativa sulla privacy',
      unconfirmedMembership: 'Non desidero diventare affiliato',
      signIn: 'Accedi',
      alreadyMember: 'Già affiliato?',
      unconfirmedMembershipNotice: 'Se non desidera procedere con l\'iscrizione al programma fedeltà, effettua il [logout].',
      unconfirmedMembershipNoticeLogout: 'logout',
    },
    unitDemand: {
      availabilityError: 'Non è stato possibile determinare la disponibilità',
      modifySearch: 'Modifica ricerca',
      contactText: 'Non ci sono unità disponibili per le date selezionate.',
      contactButton: 'Contattaci',
    },
    authStatus: {
      dashboard: 'Riepilogo’',
      myBookings: 'Le mie prenotazioni',
      pointsActivity: 'Attività a punti',
      MaiStarRewards: 'Premi MaiStar',
      myProfile: 'Il mio profilo',
      completeRegistration: 'Completa la registrazione',
      openMenu: 'Apri menu',
      closeMenu: 'Chiudi menu',
      login: 'Login',
      logout: 'Logout',
    },
    loyaltyProfile: {
      profileLocales: {
        l1050: 'Croato',
        l1040: 'Italiano',
        l1033: 'Inglese',
        l1031: 'Tedesco',
        l1060: 'Sloveno',
        l1045: 'Polacco',
        l2067: 'Olandese',
      },
      profileTitle: {
        mr: 'Sig.',
        mrs: 'Sig.ra',
      },
      identity: 'Dati personali',
      title: 'Titolo',
      firstName: 'Nome',
      lastName: 'Cognome',
      dob: 'Data di nascita',
      locale: 'Lingua',
      contact: 'Contatto',
      email: 'E-mail',
      address: 'Indirizzo',
      postalCode: 'Codice Postale',
      city: 'Città',
      phoneNumber: 'Numero di telefono',
      updateSuccess: 'Ha aggiornato correttamente il Suo profilo.',
    },
    formValidation: {
      required: '*Questo campo è obbligatorio.',
      email: 'Questo campo deve essere un indirizzo e-mail valido.',
      min: 'Questo campo deve contenere almeno',
      maxPoints: 'L’importo selezionato non è disponibile.',
      eq: 'Questo campo deve contenere',
      digits: 'cifre',
      characters: 'caratteri',
      size: 'La dimensione massima del file è',
      phone: 'La preghiamo di inserire un numero di telefono valido, con il prefisso del paese (+ oppure 00) e solo numeri e spazi, non sono ammessi altri divisori. Utilizzi il formato tipo +49 2 545 8654.',
      reqAtLeastOne: "Deve selezionare almeno un'opzione.",
      number: 'Questo dovrebbe essere un numero.',
      reqNumber: 'Deve inserire un numero valido.',
      reqFile: 'Deve selezionare un file da caricare.',
      notVerifiedMail: 'Controlli e confermi di nuovo la Sua E-mail.',
      ccard: 'Il numero di carta inserito non è valido.',
      cvv: 'Si prega di inserire un numero CVV valido. Dovrebbe contenere 3 cifre.',
      cvv34: 'Si prega di inserire un numero CVV valido. Dovrebbe contenere 3 o 4 cifre.',
      onePositive: 'Uno dei campi deve essere un numero positivo',
      birthdate: 'La data di nascita inserita non è valida.',
    },
    notification: {
      success: 'Inviato con successo.',
      error: "Errore durante l'invio.",
      moreInfo: 'Ulteriori informazioni',
    },
    fatalError: {
      title: 'Ops. Si è verificato un errore.',
      message: 'Torna alla pagina iniziale e riprovi.',
      button: 'Torna alla pagina iniziale',
      code: {
        propertyIdMissing: 'Codice di accesso non valido',
        unitOrRateMissing: 'È arrivato/a a questa pagina senza selezionare unità o tariffa.',
        phobsError10001: 'Raggiunto il limite di richieste per il servizio di prenotazione esterna',
        phobsError5008: 'Nessuna disponibilità per l’alloggio selezionato. La preghiamo di riprovare con un altro alloggio.',
        validationError: 'Errore durante la convalida dei dati inseriti. La preghiamo di rivedere i Suoi dati personali e di riprovare.',
      },
    },
    myProfile: {
      title: 'Il mio profilo',
      changeEmailText: 'Per modificare la Sua e-mail, contatti',
      changeEmailCC: 'Servizio Clienti',
      newsletter: 'Tenetemi aggiornato sulle novità, offerte e occasioni speciali per gli affiliati. ',
      registrationPrivacy1: 'Cliccando sul tasto “Registrati”, accetta l’affiliazione al programma fedeltà MaiStar e accetta i nostri {termsAndConditions}. Conferma di aver letto e capito {privacyNotice} sulle modalità di trattamento dei Suoi dati nel contesto della Sua affiliazione. Accetta il trattamento dei Suoi dati personali da parte di Maistra, incluso il Suo nome e le informazioni di contatto, la Sua data di nascita, le Sue preferenze e le informazioni in merito a come e quando utilizza i nostri servizi. Ha capito che utilizzeremo i Suoi dati per consentirle di partecipare al nostro programma fedeltà, per creare offerte speciali e per fornirle un’esperienza personalizzata, basata sulla Sua precedente interazione e sulle preferenze dichiarate e per tenerla informata sui prodotti e servizi in conformità con i Suoi interessi. Acconsente all’utilizzo da parte nostra delle informazioni che la riguardano per creare report analitici aggregati sul modo in cui vengono utilizzati i nostri servizi e per scopi di ricerche di mercato. ',
      registrationPrivacy2: 'Ha capito che ha diritto di richiedere l’accesso o il trasferimento dei Suoi dati, in qualsiasi momento, oltre alla cancellazione e alla correzione dei dati e all’obiezione al trattamento dei Suoi dati personali, contattando il responsabile della protezione dati all’indirizzo {officerEmail}. Ha diritto di presentare un reclamo all’autorità locale di controllo.',
      privacyNotice: {
        text: 'l\'Informativa sulla privacy',
        href: '/loyalty-informativa-sulla-privacy',
      },
      termsAndConditions: {
        text: 'Termini e condizioni',
        href: '/loyalty-condizioni-generali',
      },
      privacyProcedure: 'Non condividiamo i Suoi dati personali con terzi a scopi pubblicitari dei loro servizi. Non venderemo i Suoi dati personali a terzi. La preghiamo di leggere la nostra {privacyPolicy} per avere maggiori informazioni sulle procedure relative alla privacy.',
      privacyPolicy: 'Informativa sulla privacy',
      requiredFields: 'Questi campi sono obbligatori.',
      dataProtection: '<p>Rispettiamo la Sua privacy e ci impegniamo a proteggere i Suoi dati personali. Raccogliamo diversi tipi di dati personali che La riguardano, in relazione al Suo rapporto con noi e ai servizi che utilizza. In ogni caso, raccogliamo ed elaboriamo i Suoi dati personali per scopi legittimi e su valide basi legali.</p><p>Per maggiori informazioni sulla protezione dei Suoi dati personali, La preghiamo di consultare la nostra <a href="#">Informativa sulla privacy</a>.</p>',
    },
  },
  nl: {
    loading: 'bezig met laden...',
    call: 'Telefoongesprek',
    cancel: 'Annuleren',
    join: 'Meedoen',
    country: 'Land',
    search: 'Zoekopdracht',
    close: 'Sluiten',
    next: 'Volgende',
    previous: 'Vorige',
    select: 'Selecteer',
    eur: '€',
    homepage: 'Home page',
    bookNow: 'Boek nu',
    discoverMore: 'Ontdek meer',
    until: 'tot',
    learnMore: 'Ontdek meer',
    localeSwitch: {
      buttonTitle: 'Schakel van plaats',
      title: 'Voorkeuren',
      description: 'Selecteer de taal en valuta van uw voorkeur',
      selectLanguage: 'Kies een taal',
      selectCurrency: 'Kies een valuta',
      footer: 'Alle prijzen in andere valuta\'s dan euro zijn bang.',
      button: 'Voorkeuren bijwerken',
    },
    accessCode: {
      label: 'Toegangscode',
      placeholder: 'Voer de promocode in',
      confirm: 'Bevestigen',
      remove: 'Verwijderen',
      invalidAccessCode: 'De ingevoerde promocode is niet geldig.',
      validAccessCode: 'De ingevoerde promocode is geldig.',
    },
    bookingModal: {
      date: 'Aankomst en vertrek',
      guests: 'Gasten',
      accessCode: 'Promotiecode',
      howMany: 'Hoeveel gasten blijven er?',
      adults: 'Volwassenen',
      noChildren: 'Kinderen',
      selectAge: 'Kind',
      selectBtn: 'Selecteer',
      updateBtn: 'Zoekopdracht bijwerken',
      closeBtn: 'Sluiten',
      openBtn: 'Openen',
      invalidAccessCode: 'Promocode is niet geldig',
      children: 'kinderen',
      age: 'Leeftijd kind',
      note: 'De accommodatieprijzen worden per eenheid weergegeven en kunnen veranderen afhankelijk van het aantal personen en aanvullende diensten.',
      datesChanged: 'De datums zijn aangepast volgens beschikbaarheid.',
      accessCodeRemoved: 'De promotiecode is verwijderd omdat deze niet geldig is voor deze accommodatie.',
    },
    loyaltyBanner: {
      title: 'Met dankbaarheid voor je eerste verblijf',
      description: 'Word lid van de MaiStar Rewards Club en bespaar tot wel 20%. Lid worden is doodeenvoudig door je persoonsgegevens te gebruiken terwijl je je verblijf boekt.',
      button: 'Word lid',
      membershipDiscount: 'ledenkorting',
      becomeMember: 'Word lid',
      signIn: 'Inloggen',
    },
    loyaltyInvite: {
      title: 'Word lid van de MaiStar Rewards Club',
      maiStarClub: 'MaiStar Rewards Club',
      becomeMessage: 'Word lid van de MaiStar Rewards Club en bespaar tot wel 20%.',
      confirmedMembership: 'Ik wil lid worden van de MaiStar Rewards Club. Ik heb de algemene voorwaarden gelezen en accepteer deze {termsAndConditions} evenals de {privacyNotice}.',
      termsAndConditions: 'Algemene voorwaarden van het loyaliteitsplan',
      privacyNotice: 'Privacy Bericht',
      unconfirmedMembership: 'Ik wil geen lid worden',
      signIn: 'Inloggen',
      alreadyMember: 'Ben je al lid??',
      unconfirmedMembershipNotice: 'Als u niet verder wilt gaan met deelname aan het loyaliteitsprogramma, [logout].',
      unconfirmedMembershipNoticeLogout: 'log dan uit',
    },
    unitDemand: {
      availabilityError: 'De beschikbaarheid kon niet worden bepaald',
      modifySearch: 'Zoekopdracht wijzigen',
      contactText: 'Er is geen eenheid beschikbaar voor de gekozen datum.',
      contactButton: 'Neem contact met ons op',
    },
    authStatus: {
      dashboard: 'Overzicht',
      myBookings: 'Mijn boekingen',
      pointsActivity: 'Geactiveerde punten',
      MaiStarRewards: 'MaiStar Rewards',
      myProfile: 'Mijn profiel',
      completeRegistration: 'Voltooi de registratie',
      openMenu: 'Menu openen',
      closeMenu: 'Menu sluiten',
      login: 'Inloggen',
      logout: 'Uitloggen',
    },
    loyaltyProfile: {
      profileLocales: {
        l1050: 'Kroatisch',
        l1040: 'Italiaans',
        l1033: 'Engels',
        l1031: 'Duits',
        l1060: 'Sloveens',
        l1045: 'Pools',
        l2067: 'Nederlands',
      },
      profileTitle: {
        mr: 'meneer',
        mrs: 'mevrouw',
      },
      identity: 'Identiteit',
      title: 'Titel',
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      dob: 'Geboortedatum',
      locale: 'Taal',
      contact: 'Contact',
      email: 'E-mail',
      address: 'Adres',
      postalCode: 'Postcode',
      city: 'Stad',
      phoneNumber: 'Telefoonnummer',
      updateSuccess: 'Je profiel is bijgewerkt.',
    },
    formValidation: {
      required: '*Dit veld is verplicht.',
      email: 'Dit veld moet een geldig e-mailadres zijn.',
      min: 'Dit veld moet minimaal hebben',
      maxPoints: 'Het geselecteerde aantal is niet beschikbaar.',
      eq: 'Dit veld moet hebben',
      digits: 'cijfers',
      characters: 'tekens',
      size: 'Maximale bestandsgrootte is',
      phone: 'Voer een geldig telefoonnummer in, met landcode met voorvoegsel (+ of 00) en alleen cijfers en spaties, geen andere scheidingstekens toegestaan. Gebruik het formaat zoals +31 2 545 8654.',
      reqAtLeastOne: 'Je moet minimaal één optie selecteren.',
      number: 'Dit moet een nummer zijn.',
      reqNumber: 'Je moet een geldig nummer invoeren.',
      reqFile: 'Je moet een bestand selecteren om te uploaden.',
      notVerifiedMail: 'Controleer je e-mail opnieuw.',
      ccard: 'Het door jou ingevoerde kaartnummer is ongeldig.',
      cvv: 'Voer een geldig CVV-nummer in. Dat moeten 3 cijfers zijn.',
      cvv34: 'Voer een geldig CVV-nummer in. Dat moeten 3 of 4 cijfers zijn.',
      onePositive: 'Een van de velden moet een positief getal zijn',
      birthdate: 'De ingevoerde geboortedatum is niet geldig.',
    },
    notification: {
      success: 'Succesvol verzonden.',
      error: 'Fout tijdens het verzenden.',
      moreInfo: 'Meer informatie',
    },
    fatalError: {
      title: 'Oeps. Er is een fout opgetreden!',
      message: 'Keer terug naar de startpagina en probeer het opnieuw.',
      button: 'Terug naar de startpagina',
      code: {
        propertyIdMissing: 'Je bent op deze pagina terechtgekomen zonder een accommodatie te selecteren',
        unitOrRateMissing: 'Je bent op deze pagina terechtgekomen zonder een eenheid of tarief te selecteren.',
        phobsError10001: 'Verzoeklimiet voor externe boekingsservice bereikt',
        phobsError5008: 'Er is geen beschikbaarheid voor de geselecteerde unit. Probeer het opnieuw met een andere unit.',
        validationError: 'Fout bij het valideren van uw ingevoerde gegevens. Controleer uw persoonlijke gegevens en probeer het opnieuw.',
      },
    },
    myProfile: {
      title: 'Mijn profiel',
      changeEmailText: 'Om je e-mailadres te wijzigen, neem contact op met:',
      changeEmailCC: 'Klantenservice',
      newsletter: 'Houd me op de hoogte van nieuws, aanbiedingen en exclusieve ledenaanbiedingen.',
      registrationPrivacy1: 'Door te klikken op de knop "Aanmelden" hieronder accepteer je het lidmaatschap van het MaiStar loyaliteitsplan, en accepteer je de {termsAndConditions}. Je bevestigt hiermee dat je ons {privacyNotice}, over hoe wij omgaan met je persoonsgegevens m.b.t. je lidmaatschap, hebt gelezen en begrepen. Je gaat ermee akkoord dat Maistra je persoonsgegevens verwerkt inclusief je naam en contactinformatie, geboortedatum, je voorkeuren en informatie over hoe en wanneer je onze dienstverlening gebruikt. Je begrijpt dat wij deze gegevens gebruiken zodat je mee kunt doen met ons loyaliteitsplan, wij je speciale aanbiedingen kunnen doen en je een gepersonaliseerde ervaring bieden gebaseerd op onze eerdere interacties en de door jou aangegeven voorkeuren, en om je op de hoogte te houden van nieuwe producten en diensten volgens jouw interesses. Je erkent hiermee dat wij deze informatie mogen gebruiken om generieke analytische verslagen te maken over hoe onze diensten gebruikt worden, en voor marketingdoeleinden.',
      registrationPrivacy2: 'Je begrijpt dat je het recht hebt op toegang tot je gegevens, deze gegevens kunt overbrengen of een verzoek kunt indienen deze gegevens te wissen, of aan te passen. Ook kun je bezwaar maken tegen de verwerking van je persoonsgegevens door contact op te nemen met de gegevensbeschermingsverantwoordelijke via {officerEmail}. Je hebt het recht een klacht in te dienen bij de Toezichthouder AVG.',
      privacyNotice: {
        text: 'Privacyverklaring',
        href: '/loyalty-privacyverklaring',
      },
      termsAndConditions: {
        text: 'Algemene voorwaarden',
        href: '/loyalty-algemene-voorwaarden',
      },
      privacyProcedure: 'Wij delen je persoonsgegevens niet met derden voor advertentiedoeleinden. We zullen je persoonsgegevens niet verkopen aan derden. Lees ons {privacyPolicy} om meer te weten te komen over privacy procedures.',
      privacyPolicy: 'Privacyverklaring',
      requiredFields: 'Deze velden zijn verplicht.',
      dataProtection: '<p>Wij respecteren je privacy en beschermen je persoonsgegevens. Wij verzamelen verschillende soorten persoonlijke gegevens over je, afhankelijk van je relatie tot ons en de diensten die je gebruikt. In ieder geval verzamelen en verwerken wij je gegevens voor gerechtigde doeleinden en op een wettig geldende basis.</p><p>Voor meer informatie over de bescherming van je persoonsgegevens verwijzen wij naar onze <a href="#">Privacyverklaring</a>.</p>',
    },
  },
  pl: {
    loading: '', // just a placeholder for empty
    call: 'Połączenie',
    cancel: 'Anuluj',
    join: 'Dołącz',
    country: 'Kraj',
    search: 'Szukaj',
    close: 'Zamknij',
    next: 'Następny',
    previous: 'Poprzedni',
    select: 'Wybierz',
    eur: '€',
    homepage: 'Home page',
    bookNow: 'Zarezerwuj teraz',
    discoverMore: 'Odkryj więcej',
    until: 'do',
    learnMore: 'Dowiedz się więcej',
    localeSwitch: {
      buttonTitle: 'Połącz regionę',
      title: 'Preferencje',
      description: 'Wybierz preferowany język i walutę',
      selectLanguage: 'Wybierz język',
      selectCurrency: 'Wybierz walutę',
      footer: 'Ceny wyrażone we wszystkich walutach poza euro są orientacyjne.',
      button: 'Zaktualizuj preferencje',
    },
    accessCode: {
      label: 'Kod promocyjny',
      placeholder: 'Wpisz kod promocyjny',
      confirm: 'Potwierdź',
      remove: 'Usuń',
      invalidAccessCode: 'Wprowadzony kod promocyjny jest nie aktualny.',
      validAccessCode: 'Wprowadzony kod promocyjny jest ważny.',
    },
    bookingModal: {
      date: 'Przyjazd & odjazd',
      guests: 'Goście',
      accessCode: 'Kod promocyjny',
      howMany: 'Ilu gości będzie przebywać?',
      adults: 'Dorośli',
      noChildren: 'Dzieci',
      selectAge: 'Dziecko',
      selectBtn: 'Wybierz',
      updateBtn: 'Zaktualizuj wyszukiwanie',
      closeBtn: 'Zamknij',
      openBtn: 'Otwórz',
      invalidAccessCode: 'Kod promocyjny jest nie aktualny.',
      children: 'dzieci',
      age: 'Wiek dziecka',
      note: 'Ceny zakwaterowania podane są za jednostkę i mogą ulec zmianie, w zależności od liczby osób i dodatkowych usług.',
      datesChanged: 'Daty zmieniły się w zależności od dostępności.',
      accessCodeRemoved: 'Kod promocyjny został usunięty, ponieważ nie jest ważny dla tej usługi.',
    },
    loyaltyBanner: {
      title: 'Z wdzięcznością, od Twojego pierwszego pobytu',
      description: 'Zostań członkiem MaiStar Rewards Club i oszczędź nawet do 20%. Możesz łatwo dołączyć, za pomocą swoich danych osobowych, już podczas rezerwacji pobytu.',
      button: 'Zostań członkiem',
      membershipDiscount: 'zniżka członkowska',
      becomeMember: 'Zostań członkiem',
      signIn: 'Zaloguj się',
    },
    loyaltyInvite: {
      title: 'Dołącz do MaiStar Rewards Club',
      maiStarClub: 'MaiStar Rewards Club',
      becomeMessage: 'Zostań członkiem MaiStar Rewards Club i oszczędź nawet do 20%.',
      confirmedMembership: 'Chcę dołączyć do MaiStar Rewards Club. Przeczytałem i akceptuję {termsAndConditions} oraz {privacyNotice}.',
      termsAndConditions: 'Regulamin Programu Lojalnościowego',
      privacyNotice: 'Informacje o ochronie danych osobowych',
      unconfirmedMembership: 'Nie chcę zostać członkiem Klubu',
      alreadyMember: 'Jesteś już członkiem Klubu?',
      signIn: 'Zaloguj się',
      unconfirmedMembershipNotice: 'Jeśli nie chcesz kontynuować przystępowania do programu lojalnościowego - [logout].',
      unconfirmedMembershipNoticeLogout: 'wyloguj się',
    },
    unitDemand: {
      availabilityError: 'Nie można określić dostępności',
      modifySearch: 'Zmodyfikuj wyszukiwanie',
      contactText: 'Niestety nie ma dostępnych miejsc w wybranym okresie.',
      contactButton: 'Skontaktuj się z nami',
    },
    authStatus: {
      dashboard: 'Przegląd',
      myBookings: 'Moje rezerwacje',
      pointsActivity: 'Aktywne punkty',
      MaiStarRewards: 'Nagrody MaiStar',
      myProfile: 'Mój profil',
      completeRegistration: 'Zakończ rejestrację',
      openMenu: 'Otwórz menu',
      closeMenu: 'Zamknij menu',
      login: 'Zaloguj sie',
      logout: 'Wyloguj się',
    },
    loyaltyProfile: {
      profileLocales: {
        l1050: 'chorwacki',
        l1040: 'włoski',
        l1033: 'angielski',
        l1031: 'niemiecki',
        l1060: 'słoweński',
        l1045: 'polski',
        l2067: 'niderlandzki',
      },
      profileTitle: {
        mr: 'Pan',
        mrs: 'Pani',
      },
      identity: 'Tożsamość',
      title: 'Tytuł',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      dob: 'Data urodzin',
      locale: 'Język',
      contact: 'Kontakt',
      email: 'Email',
      address: 'Adres',
      postalCode: 'Kod pocztowy',
      city: 'Miasto',
      phoneNumber: 'Numer telefonu',
      updateSuccess: 'Pomyślnie zaktualizowałeś swój profil.',
    },
    formValidation: {
      required: '+To pole jest wymagane.',
      email: 'To pole musi być prawidłowym adresem e-mail.',
      min: 'To pole musi mieć co najmniej',
      maxPoints: 'Wybrana kwota jest niedostępna.',
      eq: 'To pole musi mieć',
      digits: 'cyfry',
      characters: 'znaków',
      size: 'Maksymalny rozmiar pliku to',
      phone: 'Wprowadź prawidłowy numer telefonu, z kodem kraju z prefiksem (+ lub 00) i tylko cyframi oraz spacjami, bez innych separatorów. Użyj wzoru jak. +49 2 545 8654.',
      reqAtLeastOne: 'Musisz wybrać co najmniej jedną opcję.',
      number: 'To powinien być numer.',
      reqNumber: 'Musisz wpisać poprawny numer.',
      reqFile: 'Musisz wybrać plik do przesłania.',
      notVerifiedMail: 'Sprawdź i ponownie zweryfikuj swój adres e-mail.',
      ccard: 'Wprowadzony numer karty jest nieprawidłowy.',
      cvv: 'Podaj prawidłowy numer CVV. Powinien zawierać 3 cyfry.',
      cvv34: 'Podaj prawidłowy numer CVV. Powinien zawierać 3 lub 4 cyfry.',
      onePositive: 'Jedno z pól musi być liczbą dodatnią',
      birthdate: 'Wprowadzona data urodzenia jest nieprawidłowa.',
    },
    notification: {
      success: 'Pomyślnie przesłano.',
      error: 'Błąd podczas przesyłania.',
      moreInfo: 'Więcej informacji',
    },
    fatalError: {
      title: 'Przepraszamy, ale coś poszło nie tak.',
      message: 'Wróć do strony głównej i spróbuj ponownie.',
      button: 'Wróć do strony głównej',
      code: {
        propertyIdMissing: 'Dotarłeś do tej strony bez wybranego obiektu.',
        unitOrRateMissing: 'Dotarłeś do tej strony bez wybrania jednostki lub stawki.',
        phobsError10001: 'Osiągnięto limit żądań w zewnętrznej usłudze rezerwacji',
        phobsError5008: 'Wybrana jednostka jest niedostępna. Spróbuj ponownie, wybierając inną jednostkę.',
        validationError: 'Błąd podczas sprawdzania poprawności wprowadzonych danych. Sprawdź swoje dane osobowe i spróbuj ponownie.',
      },
    },
    myProfile: {
      title: 'Mój profil',
      changeEmailText: 'W celu zmiany adresu e-mail, prosimy o kontakt',
      changeEmailCC: 'Obsługa klienta',
      newsletter: 'Informuj mnie na bieżąco o nowościach, ofertach i ekskluzywnych ofertach członkowskich. ',
      registrationPrivacy1: 'Klikając poniższy przycisk „Zarejestruj się”, akceptujesz członkostwo w programie lojalnościowym MaiStar, a także nasz {termsAndConditions}. Potwierdzasz, że przeczytałeś i zrozumiałeś {privacyNotice} dotyczące tego, w jaki sposób przetwarzamy Twoje dane w kontekście Twojego członkostwa. Zgadzasz się, że Maistra będzie przetwarzać dane osobowe, w tym imię i nazwisko oraz dane kontaktowe, Twoją datę urodzenia, preferencje oraz informacje o tym, jak i kiedy korzystasz z naszych usług. Rozumiesz, że wykorzystamy te dane, aby umożliwić Ci udział w naszym programie lojalnościowym, stworzyć specjalne oferty i zapewnić Ci spersonalizowane doświadczenia w oparciu o nasze wcześniejsze interakcje i Twoje określone preferencje oraz aby informować Cię o naszych produktach i usługach zgodnych z Twoimi zainteresowaniami. Przyjmujesz do wiadomości, że możemy wykorzystać informacje o Tobie, do tworzenia zbiorczych raportów analitycznych dotyczących sposobu korzystania z naszych usług oraz do celów badania rynku. ',
      registrationPrivacy2: 'Rozumiesz, że masz prawo w dowolnym momencie zażądać dostępu do swoich danych, ich przeniesienia, a także do ich usunięcia, poprawienia oraz do wniesienia sprzeciwu wobec przetwarzania danych osobowych, kontaktując się z Inspektorem ochrony danych, pod adresem {officerEmail}. Masz prawo wnieść skargę do lokalnego organu nadzorczego.',
      privacyNotice: {
        text: 'Informacje o ochronie danych osobowych',
        href: '/loyalty-informacje-o-ochronie-danych-osobowych',
      },
      termsAndConditions: {
        text: 'Regulamin',
        href: '/loyalty-ogolne-warunki',
      },
      privacyProcedure: 'Nie udostępniamy Twoich danych osobowych podmiotom trzecim, w celu reklamowania ich usług. Nie sprzedamy Twoich danych osobowych podmiotom trzecim. Prosimy byś zapoznał się naszą {privacyPolicy}, aby uzyskać więcej o procedurach prywatności.',
      privacyPolicy: 'Powiadomienie o ochronie prywatności',
      requiredFields: 'Te pola są wymagane.',
      dataProtection: '<p>Szanujemy Twoją prywatność i jesteśmy zobowiązani do ochrony Twoich danych osobowych. Zbieramy różne rodzaje Twoich danych osobowych, w zależności od Twoich relacji z nami oraz usług, z których korzystasz. Zawsze gromadzimy i przetwarzamy Twoje dane osobowe w uzasadnionych celach i na ważnej podstawie prawnej.</p><p>Aby uzyskać więcej informacji na temat ochrony Twoich danych osobowych, zapoznaj się z naszą <a href="#"> Informacja o ochronie prywatności</a>.</p>',
    },
  },
  si: {
    loading: '', // samo označba mesta za prazno polje
    call: 'Klic',
    cancel: 'Prekliči',
    join: 'Pridruži se',
    country: 'Država',
    search: 'Išči',
    close: 'Zapri',
    next: 'Naslednji',
    previous: 'Prejšnji',
    select: 'Izberi',
    eur: '€',
    homepage: 'Home page',
    bookNow: 'Rezervirajte zdaj',
    discoverMore: 'Odkrijte več',
    until: 'do',
    learnMore: 'Odkrijte več',
    localeSwitch: {
      buttonTitle: 'Nastavitve jezika',
      title: 'Nastavitve',
      description: 'Izberite želen jezik in valuto.',
      selectLanguage: 'Izberite jezik',
      selectCurrency: 'Izberite valuto',
      footer: 'Cene, izražene v vseh valutah z izjemo evra, so okvirne.',
      button: 'Posodobi nastavitve',
    },
    accessCode: {
      label: 'Promocijska koda',
      placeholder: 'Vpišite promocijsko kodo',
      confirm: 'Potrdi',
      remove: 'Odstrani',
      invalidAccessCode: 'Vpisana promocijska koda ni veljavna.',
      validAccessCode: 'Vpisana promocijska koda je veljavna.',
    },
    bookingModal: {
      date: 'Prihod in odhod',
      guests: 'Gostje',
      accessCode: 'Promocijska koda',
      howMany: 'Koliko gostov bo bivalo?',
      adults: 'Odrasli',
      noChildren: 'Otroci',
      selectAge: 'Otrok',
      selectBtn: 'Izberi',
      updateBtn: 'Posodobi iskanje',
      closeBtn: 'Zapri',
      openBtn: 'Odpri',
      invalidAccessCode: 'Promocijska koda ni veljavna',
      children: 'otroci',
      age: 'Starost otroka',
      note: 'Cene namestitve so navedene po enoti in se lahko spremenijo glede na število oseb in dodatne storitve.',
      datesChanged: 'Datumi so se spremenili glede na razpoložljivost.',
      accessCodeRemoved: 'Promocijska koda je bila odstranjena, ker ni veljavna za ta objekt.',
    },
    loyaltyBanner: {
      title: 'S hvaležnostjo za vaše prvo bivanje',
      description: 'Postanite član kluba MaiStar Rewards Club in prihranite do 20 %. Klubu se lahko enostavno pridružite, tako da uporabite osebne podatke, ki jih potrebujete za rezervacijo svojega bivanja.',
      button: 'Postanite član/-ica',
      membershipDiscount: 'popust za člane',
      becomeMember: 'Postanite član/-ica',
      signIn: 'Prijava',
    },
    loyaltyInvite: {
      title: 'Pridružite se klubu MaiStar Rewards Club',
      maiStarClub: 'MaiStar Rewards Club',
      becomeMessage: 'Postanite član kluba MaiStar Rewards Club in prihranite do 20 %.',
      confirmedMembership: 'Želim se pridružiti klubu MaiStar Rewards Club. Prebral/-a sem in sprejemam {termsAndConditions} ter {privacyNotice}.',
      termsAndConditions: 'Pogoji in določila programa zvestobe',
      privacyNotice: 'Obvestilo o zasebnosti',
      unconfirmedMembership: 'Ne želim se včlaniti',
      alreadyMember: 'Ali ste že včlanjeni?',
      signIn: 'Prijava',
      unconfirmedMembershipNotice: 'Če ne želite nadaljevati s pridružitvijo programu zvestobe, prosimo, da se [logout].',
      unconfirmedMembershipNoticeLogout: 'odjavite',
    },
    unitDemand: {
      availabilityError: 'Razpoložljivosti ni bilo mogoče določiti',
      modifySearch: 'Spremeni iskanje',
      contactText: 'Na žalost ni razpoložljivih enot v izbranem obdobju.',
      contactButton: 'Obrnite se na nas',
    },
    authStatus: {
      dashboard: 'Pregled',
      myBookings: 'Moje rezervacije',
      pointsActivity: 'Aktivirane točke',
      MaiStarRewards: 'Nagrade MaiStar',
      myProfile: 'Moj profil',
      completeRegistration: 'Dokončaj registracijo',
      openMenu: 'Odpri meni',
      closeMenu: 'Zapri meni',
      login: 'Prijava',
      logout: 'Odjava',
    },
    loyaltyProfile: {
      profileLocales: {
        l1050: 'Hrvaško',
        l1040: 'Italijansko',
        l1033: 'Angleško',
        l1031: 'Nemško',
        l1060: 'Slovensko',
        l1045: 'Poljsko',
        l2067: 'Nizozemsko',
      },
      profileTitle: {
        mr: 'G.',
        mrs: 'Ga.',
      },
      identity: 'Osebni podatki',
      title: 'Naziv',
      firstName: 'Ime',
      lastName: 'Priimek',
      dob: 'Datum rojstva',
      locale: 'Jezik',
      contact: 'Podatki za stik',
      email: 'E-poštni naslov',
      address: 'Naslov',
      postalCode: 'Poštna številka',
      city: 'Kraj',
      phoneNumber: 'Telefonska številka',
      updateSuccess: 'Uspešno ste posodobili svoj profil.',
    },
    formValidation: {
      required: '*To polje je obvezno.',
      email: 'To polje mora vsebovati veljaven e-poštni naslov.',
      min: 'To polje mora vsebovati vsaj',
      maxPoints: 'Izbrani znesek ni na voljo.',
      eq: 'To polje mora vsebovati',
      digits: 'števke',
      characters: 'znaki',
      size: 'Največja velikost datoteke je',
      phone: 'Vnesite veljavno telefonsko številko s kodo države s predpono (+ ali 00) in samo številke in presledke, brez znakov za ločevanje. Uporabite obliko, kot je: +49 2 545 8654.',
      reqAtLeastOne: 'Izbrati morate vsaj eno možnost.',
      number: 'To bi morala biti številka.',
      reqNumber: 'Vnesti morate veljavno številko.',
      reqFile: 'Izbrati morate datoteko za prenos.',
      notVerifiedMail: 'Preverite in potrdite svoj e-poštni naslov še enkrat.',
      ccard: 'Vpisana številka kartice ni veljavna.',
      cvv: 'Vpišite veljavno številko CVV, Vsebovati mora 3 števke.',
      cvv34: 'Vpišite veljavno številko CVV, Vsebovati mora 3 ali 4 števke.',
      onePositive: 'Eno od polj mora biti pozitivno število',
      birthdate: 'Vnesen datum rojstva ni pravilen.',
    },
    notification: {
      success: 'Uspešno poslano.',
      error: 'Napaka pri pošiljanju.',
      moreInfo: 'Več informacij',
    },
    fatalError: {
      title: 'Žal nam je, vendar je prišlo je do napake.',
      message: 'Vrnite se na domačo stran in poskusite znova.',
      button: 'Nazaj na domačo stran',
      code: {
        propertyIdMissing: 'Na tej strani ste pristali, ne da bi izbrali objekt.',
        unitOrRateMissing: 'Na to stran ste prispeli, ne da bi izbrali enoto ali ceno.',
        phobsError10001: 'Dosežena omejitev zahtev za zunanjo storitev rezervacij',
        phobsError5008: 'Izbrana enota ni na razpolago. Poskusite znova z drugo enoto.',
        validationError: 'Napaka pri potrjevanju vaših vnesenih podatkov. Preverite svoje osebne podatke in poskusite znova.',
      },
    },
    myProfile: {
      title: 'Moj profil',
      changeEmailText: 'Za spremembo e-poštnega naslova se obrnite na',
      changeEmailCC: 'Služba za pomoč uporabnikom',
      newsletter: 'Obveščajte me o novicah, ponudbah in ekskluzivnih ponudbah za člane. ',
      registrationPrivacy1: 'S klikom na spodnji gumb »Registracija« sprejemate članstvo v programu zvestobe MaiStar ter sprejemate naša {termsAndConditions}. Potrjujete, da ste prebrali in razumeli {privacyNotice} o tem, kako obdelujemo vaše podatke v okviru vašega članstva. Strinjate se, da bo Maistra obdelovala vaše osebne podatke, vključno z vašim imenom in kontaktnimi podatki, datumom rojstva, vašimi željami in informacijami o tem, kako in kdaj uporabljate naše storitve. Razumete, da bomo te podatke uporabili, da vam omogočimo sodelovanje v našem programu zvestobe, da ustvarjamo posebne ponudbe in da vam zagotavljamo prilagojeno izkušnjo na podlagi naših prejšnjih interakcij, vaših navedenih preferenc ter vas obveščamo o naših izdelkih in storitvah v skladu s vašimi interesi. Potrjujete, da lahko podatke o vas uporabimo za ustvarjanje združenih analitičnih poročil o tem, kako smo naše storitve uporabljali, in za namene tržnih raziskav. ',
      registrationPrivacy2: 'Razumete, da imate pravico, da kadar koli zahtevate dostop do svojih podatkov ali jih prenesete, kot tudi, da jih izbrišemo, da jih popravite ali ugovarjate obdelavi osebnih podatkov tako, da se obrnete na pooblaščeno osebo za varstvo podatkov na {officerEmail}. Imate pravico vložiti pritožbo pri lokalnem nadzornem organu.',
      privacyNotice: {
        text: 'Obvestilo o zasebnosti',
        href: '/loyalty-obvestilo-o-zasebnosti',
      },
      termsAndConditions: {
        text: 'Določila in pogoji',
        href: '/loyalty-splosni-pogoji',
      },
      privacyProcedure: 'Vaših osebnih podatkov ne dajemo v skupno rabo s tretjimi osebami za namen oglaševanja njihovih storitev. Vaših osebnih podatkov ne bomo prodali tretjim osebam. Preberite našo {privacyPolicy}, da izveste več o postopkih zaščite podatkov.',
      privacyPolicy: 'Obvestilo o zasebnosti',
      requiredFields: 'Ta polja so obvezna.',
      dataProtection: '<p>Spoštujemo vašo zasebnost in smo zavezani k zaščiti vaših osebnih podatkov. O vas zbiramo različne vrste osebnih podatkov, kar je odvisno od vašega razmerja z nami in storitev, ki jih uporabljate. V vsakem primeru pa vaše osebne podatke zbiramo in obdelujemo v zakonite namene in v skladu z veljavno pravno podlago.</p><p>Za več informacij o zaščiti vaših osebnih podatkov glejte naše <a href="#">Obvestilo o zasebnosti</a>.</p>',
    },
  },
}

const datetimeFormats = {
  en: {
    month: {
      month: 'long',
    },
  },
  de: {
    month: {
      month: 'long',
    },
  },
  hr: {
    month: {
      month: 'long',
    },
  },
  it: {
    month: {
      month: 'long',
    },
  },
  nl: {
    month: {
      month: 'long',
    },
  },
  pl: {
    month: {
      month: 'long',
    },
  },
  si: {
    month: {
      month: 'long',
    },
  },
}

export {
  messagesCommon,
  datetimeFormats,
}
