/* global WWW_HOME_URL, PREFIX */

export default {
  name: 'LoyaltyBenefits',
  template: `
    <div class="loyalty-link-banner">
      <h3 class="no-margin-top">{{ $t('linkBanner.unlockBenefits') }}</h3>
      <div class="loyalty-link-banner__flex">
        <div class="loyalty-link-banner__box">
          <p class="no-margin">{{ $t('linkBanner.youEarn') }}</p>
          <strong>{{ pointsEarned }} {{ $t('linkBanner.points') }}</strong>
        </div>

        <div class="loyalty-link-banner__text">
          <p class="no-margin-top" v-html="$t('linkBanner.clickLink', [email])" />
          <small>{{ $t('linkBanner.timeDisclaimer') }}</small>
        </div>
      </div>
    </div>

    <div class="loyalty-banner loyalty-banner--benefits">
      <div class="loyalty-banner__wrapper">
        <div class="loyalty-banner__logo">
          <img src="https://res.cloudinary.com/maistra/image/upload/v1636445351/Loyalty/maistar-rewards-club.png" alt="Maistar Rewards Club" width="106" height="96">
        </div>

        <div class="loyalty-banner__text">
          <div>
            <h4>{{ $t('loyaltyBenefits.title') }}</h4>
            <ul v-html="$t('loyaltyBenefits.list')" />
            <a class="button button--maistar" :href="WWW_HOME_URL + PREFIX + '/loyalty'">
              {{ $t('learnMore') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  `,

  props: {
    totalPrice: {
      type: Number,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },

  setup (props) {
    const pointsEarned = Math.floor(props.totalPrice * 10)

    return {
      WWW_HOME_URL,
      PREFIX,
      pointsEarned,
    }
  },
}
