import { requestSignIn } from '../../use-auth-observer'

export default {
  name: 'LoyaltyBanner',
  template: `
    <div class="loyalty-banner">
      <div class="loyalty-banner__wrapper">
        <div class="loyalty-banner__logo">
          <img src="https://res.cloudinary.com/maistra/image/upload/v1636445351/Loyalty/maistar-rewards-club.png" alt="Maistar Rewards Club" width="106" height="96">
        </div>

        <div class="loyalty-banner__text">
          <div>
            <h4 v-html="$t('loyaltyBanner.title')" />
            <p v-html="$t('loyaltyBanner.description')" />
          </div>
          <button class="button button--maistar become-member-button" @click="requestSignIn()">
            {{ $t('loyaltyBanner.button') }}
          </button>
        </div>
      </div>
    </div>
  `,
  setup () {
    return { requestSignIn }
  },
}
