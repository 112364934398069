/* global USD_CONVERSION_RATE  */

import { useCurrencyObserver } from '../use-currency-observer'

class Price {
  constructor () {
    if (typeof arguments[0] === 'number') {
      [this.price, this.currency] = arguments
    } else {
      let [rawPrice, selector, rawPriceForCurrency] = arguments
      this.price = parseFloat(rawPrice[selector])
      rawPrice = rawPriceForCurrency ?? rawPrice
      this.currency = rawPrice['@Currency']
    }
  }

  get display () {
    const priceFormat = (price) => new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price)
    const currencyFormat = (currency) => currency.replace('EUR', '€')

    if (useCurrencyObserver().value === 'USD') {
      return `$${priceFormat(this.price * USD_CONVERSION_RATE)}`
    }

    return `${priceFormat(this.price)}\xa0${currencyFormat(this.currency)}`
  }
}

export { Price }
