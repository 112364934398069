/* global Vue */

export default {
  name: 'Modal',
  template: `
  <div v-show="isOpen" class="modal">
    <div class="modal__overlay" @click="$emit('closeModal')"></div>
    <div class="modal__container">
      <div class="modal__header" data-sl="mask">
        <div class="modal__button">
          <button @click="$emit('closeModal')">
            <span>{{ $t('cancel') }}</span>
            <i class="icon-close"></i>
          </button>
        </div>

        <h2 class="no-margin">
          <span v-if="title">{{ title }}</span>
        </h2>
      </div>

      <div class="modal__content">
        <slot />
      </div>

      <slot name="footer" />
    </div>
  </div>
  `,
  props: {
    isOpen: Boolean,
    title: String,
  },
  emits: ['closeModal'],
  setup (props) {
    Vue.watch(() => props.isOpen, (isOpen) => {
      if (isOpen) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    })
  },
}
