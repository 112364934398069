/* global Vue, VueI18n, LOYALTY_API, LOCALE, PREFIX, DATA_LAYER, VeeValidate */

import { AuthService, fetchWithTokenAsync } from '../../AuthService'
import CountriesDropdown from '../../apps/components/CountriesDropdown'
import PhoneNumber from '../../apps/components/PhoneNumber'
import '../../validation-rules'

import { messagesCommon } from '../../i18n/translations-common'
import { setErrorAndStopLoading, setSuccessAndStopLoading, startLoading, stopLoading } from '../../apps/components/Notifications'
const m = messagesCommon[LOCALE].loyaltyProfile

const LoyaltyProfile = {
  name: 'LoyaltyProfile',
  components: { CountriesDropdown, PhoneNumber },
  template: `
<form class="loyalty-form" ref="formElement">
  <div class="loyalty-my-profile__identity loyalty-form__border">
    <h2 class="h4 uppercase">{{ $t('loyaltyProfile.identity') }}</h2>
    <hr>
    <div class="form-element">
      <label for="title">{{ $t('loyaltyProfile.title') }} *</label>
      <select class="select-input" id="title" name="title" v-model="form.title">
        <option value="mr">{{ $t('loyaltyProfile.profileTitle.mr') }}</option>
        <option value="ms">{{ $t('loyaltyProfile.profileTitle.mrs') }}</option>
      </select>
      <span class="field-error">{{ formErrors.title }}</span>
    </div>

    <div class="form-element">
      <label for="firstName">{{ $t('loyaltyProfile.firstName') }} *</label>
      <input id="firstName" name="firstName" type="text"  v-model="form.firstName">
      <span class="field-error">{{ formErrors.firstName }}</span>
    </div>

    <div class="form-element">
      <label for="lastName">{{ $t('loyaltyProfile.lastName') }} *</label>
      <input id="lastName" name="lastName" type="text" v-model="form.lastName" >
      <span class="field-error">{{ formErrors.lastName }}</span>
    </div>

    <div class="form-element">
      <label for="birthdate">{{ $t('loyaltyProfile.dob') }} *</label>
      <input id="birthdate" name="birthdate" type="date" v-model="form.birthdate" :max="new Date().toISOString().substring(0,10)">
      <span class="field-error">{{ formErrors.birthdate }}</span>
    </div>

    <div v-if="m" class="form-element">
      <label for="locale">{{ $t('loyaltyProfile.locale') }}</label>
      <select class="select-input" id="locale" name="locale" v-model="form.locale">
        <option v-for="l in profileLocales" :value="l">{{ m.profileLocales['l' + l] }}</option>
      </select>
    </div>
  </div>

  <div class="loyalty-my-profile__contact loyalty-form__border">
    <h2 class="h4">{{ $t('loyaltyProfile.contact') }}</h2>
    <hr>
    <div class="form-element">
      <label for="profileEmail">{{ $t('loyaltyProfile.email') }}</label>
      <input id="profileEmail" type="email" disabled v-model="form.email">
    </div>

    <p>{{ $t('myProfile.changeEmailText') }} <a href="mailto:hello@maistra.hr" target="_blank">{{ $t('myProfile.changeEmailCC') }}</a>.</p>

    <div class="form-element">
      <label for="address">{{ $t('loyaltyProfile.address') }} *</label>
      <input id="address" name="address" type="text" v-model="form.address">
      <span class="field-error">{{ formErrors.address }}</span>
    </div>

    <div class="form-element">
      <label for="postalCode">{{ $t('loyaltyProfile.postalCode') }} *</label>
      <input id="postalCode" name="postalCode" type="text" v-model="form.postalCode">
      <span class="field-error">{{ formErrors.postalCode }}</span>
    </div>

    <div class="form-element">
      <label for="city">{{ $t('loyaltyProfile.city') }} *</label>
      <input id="city" name="city" type="text" v-model="form.city">
      <span class="field-error">{{ formErrors.city }}</span>
    </div>

    <div class="form-element">
      <CountriesDropdown :countryCode="form.country" id="countryDropdown" @countryChanged="form.country = $event.code_3; countryPrefix = $event.phone_code" :label="$t('country') + ' *'" />
      <input id="country" type="hidden" v-model="form.country">
      <span class="field-error">{{ formErrors.country }}</span>
    </div>

    <div class="form-element">
      <PhoneNumber :countryPrefix="countryPrefix" :phoneNumber="form.phonenumber" id="phonenumberField" @phoneNumberChanged="form.phonenumber = $event" :label="$t('loyaltyProfile.phoneNumber') + ' *'" />
      <input id="phonenumber" type="hidden" v-model="form.phonenumber">
      <span class="field-error">{{ formErrors.phonenumber }}</span>
    </div>

    <div class="form-element">
      <label class="label-checkbox label-checkbox--margin">
        <input id="newsletter" name="newsletter" type="checkbox" checked v-model="form.newsletter">
        <span>{{ $t('myProfile.newsletter') }}</span>
      </label>
    </div>

    <div v-show="registration && !isSubmitting" class="note">
      <i18n-t tag="p" class="no-margin-top" keypath="myProfile.registrationPrivacy1" scope="global">
        <template #privacyNotice>
          <a :href="PREFIX + $t('myProfile.privacyNotice.href')" target="_blank">{{ $t('myProfile.privacyNotice.text') }}</a>
        </template>
        <template #termsAndConditions>
          <a :href="PREFIX + $t('myProfile.termsAndConditions.href')" target="_blank">{{ $t('myProfile.termsAndConditions.text') }}</a>
        </template>
      </i18n-t>
      <i18n-t tag="p" class="no-margin-bottom" keypath="myProfile.registrationPrivacy2" scope="global">
        <template #officerEmail>
          <a href="mailto:dpo@maistra.hr" target="_blank">dpo@maistra.hr</a>
        </template>
      </i18n-t>
    </div>

    <p>* {{ $t('myProfile.requiredFields') }}</p>
  </div>
</form>

<div v-show="registration && !isSubmitting">
  <br>
  <button class="button" @click="saveProfile()">{{ $t('join') }}</button>
</div>

<div class="button-group" v-show="!registration && !isSubmitting">
  <button class="button button--outline" @click="$router.push('/')">{{ $t('cancel') }}</button>
  <button class="button button--maistar" @click="saveProfile()">{{ $t('confirm') }}</button>
</div>
`,
  emits: ['success'],
  props: {
    profile: {
      type: Object,
      default: {},
    },
    registration: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { t } = VueI18n.useI18n()
    const form = Vue.reactive({})
    const formErrors = Vue.reactive({})
    const countryPrefix = Vue.ref('')
    const formElement = Vue.ref(null)

    const validationSchema = {
      title: 'required',
      firstName: 'required|min:1',
      lastName: 'required|min:1',
      birthdate: 'required|birthdate',
      address: 'required',
      postalCode: 'required',
      city: 'required',
      country: 'required',
      phonenumber: 'required|phone',
    }
    const profileLocales = ['1050', '1040', '1033', '1031', '1060']

    const { handleSubmit, errors, isSubmitting } = VeeValidate.useForm({
      validationSchema,
      initialValues: form,
    })
    Vue.watch(isSubmitting, () => {
      if (Object.keys(errors.value).length && formElement.value) {
        const highlight = Array.from(formElement.value.querySelectorAll('.field-error')).filter(er => er.innerHTML)[0] || null
        if (highlight) { highlight.parentNode.scrollIntoView({ behavior: 'smooth' }) }
      }
    })

    Vue.watch(props.profile, (currentValue) => {
      Object.assign(form, currentValue)
    }, { immediate: true, deep: true })

    Object.keys(validationSchema).forEach((filed) => {
      const { value: fieldValue, errorMessage: fieldError } = VeeValidate.useField(filed)
      form[filed] = fieldValue
      formErrors[filed] = fieldError
    })

    const saveProfile = handleSubmit(async () => {
      startLoading('profile-update')
      try {
        const dmdUniqueUserId = window.localStorage.getItem('DMD_uniqueUserId')
        if (dmdUniqueUserId) { form.uniqueId = dmdUniqueUserId }
        const responseObj = await fetchWithTokenAsync(`${LOYALTY_API}/api/v1/loyaltyprofiles/upsert`,
          AuthService.accessToken, 'POST', form)
        if (parseInt(responseObj?.value) > 0) {
          const successPayload = { details: t('loyaltyProfile.updateSuccess'), code: 'updateProfile' }
          setSuccessAndStopLoading(successPayload)
          emit('success', successPayload)
          window.localStorage.removeItem('DMD_uniqueUserId')
          AuthService.fetchAndSaveUserData()
        } else if (responseObj?.message) {
          throw new Error(responseObj?.message)
        } else {
          throw new Error('update loyalty profile failed')
        }
        stopLoading('profile-update')
      } catch (e) {
        setErrorAndStopLoading(e)
      }

      if (props.registration && DATA_LAYER != null) {
        DATA_LAYER.recordUserInteraction('join_loyalty')
      }
    })

    return { form, formErrors, profileLocales, saveProfile, m, PREFIX, countryPrefix, formElement, isSubmitting }
  },
}

const useLoyaltyProfile = () => {
  const profile = Vue.reactive({
    title: AuthService.memberProfile?.title,
    firstName: AuthService.memberProfile?.firstName,
    lastName: AuthService.memberProfile?.lastName,
    email: AuthService.memberProfile?.email,
    address: AuthService.memberProfile?.address,
    city: AuthService.memberProfile?.city,
    postalCode: AuthService.memberProfile?.postalCode,
    country: AuthService.memberProfile?.country,
    _birthdate: AuthService.memberProfile?.birthdate ? new Date(AuthService.memberProfile?.birthdate.substring(0, 10)) : '',
    get birthdate () {
      if (this._birthdate) {
        return this._birthdate.toISOString().split('T')[0]
      }
      return ''
    },
    set birthdate (date) {
      this._birthdate = new Date(date)
    },
    phonenumber: AuthService.memberProfile?.phonenumber,
    locale: AuthService.memberProfile?.locale,
  })
  return { profile }
}

export { LoyaltyProfile, useLoyaltyProfile }
