/* global Vue */

const _authObserver = Vue.reactive({
  signInRequested: false,
  displayName: null,
  loyaltyId: null,
  accountId: null,
})

/**
 * AuthService works well as a singleton between apps that are bundled together.
 * We use this for apps like PropertyListing which are bundled separately and need to communicate with AuthStatus via body attributes + observers.
 */
function useAuthObserver () {
  const observer = new MutationObserver(([e]) => {
    const { requestSignIn, displayName, loyaltyId, accountId } = JSON.parse(e.target.dataset.authentication) ?? {}
    _authObserver.displayName = displayName ?? null
    _authObserver.loyaltyId = loyaltyId ?? null
    _authObserver.accountId = accountId ?? null
    _authObserver.signInRequested = requestSignIn != null
  })

  observer.observe(document.body, {
    attributes: true,
    attributeFilter: ['data-authentication'],
  })

  return {
    authObserver: {
      signInRequested: Vue.computed(() => _authObserver.signInRequested),
      displayName: Vue.computed(() => _authObserver.displayName),
      loyaltyId: Vue.computed(() => _authObserver.loyaltyId),
      accountId: Vue.computed(() => _authObserver.accountId),
    },
    update: (authenticationData) => {
      document.body.dataset.authentication = JSON.stringify(authenticationData)
    },
  }
}

function requestSignIn () {
  document.body.dataset.authentication = JSON.stringify({ requestSignIn: true })
}

export { useAuthObserver, requestSignIn }
