/* global WWW_HOME_URL, HOME_TITLE, LOGO */

export default {
  name: 'SimpleHeader',
  template: `
  <header class="header header--simple">
    <div class="header__top">
      <div class="header-logos">
        <a :href="WWW_HOME_URL" :title="HOME_TITLE" class="header-logos__maistra">
          <picture>
            <source media="(min-width: 992px)" :srcset="LOGO.desktop">
            <source media="print" :srcset="LOGO.desktop">
            <img :src="LOGO.mobile" :alt="HOME_TITLE" width="48" height="48">
          </picture>
        </a>
      </div>
      <div class="header__icons hide-on-print">
        <a v-if="showPhone" href="tel:+385%2052%20800%20250" class="header__icon" title="$t('call')">
          <i class="icon-call"></i>
        </a>
        <button v-if="showHideModal" class="button button--blank" @click="$emit('hideModal')" :title="$t('checkRates.closeButtonLabel')">
          <span class="hide-sm-down">{{ $t('checkRates.closeButtonLabel') }}</span>
          <i class="icon-close"></i>
        </button>
      </div>
    </div>
  </header>
  `,
  props: ['showPhone', 'showHideModal'],
  emits: ['hideModal'],
  setup () {
    return { WWW_HOME_URL, HOME_TITLE, LOGO }
  },
}
