/* global VeeValidate, LOCALE */

import { messagesCommon } from './i18n/translations-common'
const m = messagesCommon[LOCALE].formValidation

VeeValidate.defineRule('required', value => {
  if (!value || !value.length) {
    return m.required
  }
  return true
})

VeeValidate.defineRule('digits', (value, [size]) => {
  if (!value || !value.length) {
    return true
  }
  const isValid = /^[0-9]*$/.test(value) && value.length === parseInt(size)
  if (!isValid) {
    return `${m.eq} ${size} ${m.digits}.`
  }
  return true
})

VeeValidate.defineRule('email', value => {
  if (!value || !value.length) {
    return true
  }
  if (!/^.{1,64}@.+$/i.test(value)) {
    return m.email
  }
  return true
})

VeeValidate.defineRule('digits', (value, [size]) => {
  if (!value || !value.length) {
    return true
  }
  const isValid = /^[0-9]*$/.test(value) && value.length === parseInt(size)
  if (!isValid) {
    return `${m.eq} ${size} ${m.digits}.`
  }
  return true
})

VeeValidate.defineRule('birthdate', (value) => {
  if (!/^\d{4}-\d{2}-\d{2}$/i.test(value)) {
    return m.birthdate
  }
  if (value < '1900-01-01' || value > new Date().toISOString().substring(0, 10)) {
    return m.birthdate
  }
  return true
})

VeeValidate.defineRule('min', (value, [limit]) => {
  if (!value || !value.length) {
    return true
  }
  if (value.length < limit) {
    return `${m.min} ${limit} ${m.characters}.`
  }
  return true
})

VeeValidate.defineRule('eq', (value, [limit]) => {
  if (!value || !value.length) {
    return true
  }
  if (value.length !== parseInt(limit)) {
    return `${m.eq} ${limit} ${m.characters}.`
  }
  return true
})

VeeValidate.defineRule('phone', (value, [limit]) => {
  if (!value || !value.length) {
    return true
  }
  if (!/^(\+|00)[1-9](\d *){8,11}\d$/.test(value)) {
    return m.phone
  }
  if (value.length > limit) {
    return `${m.max} ${limit} ${m.characters}.`
  }
  return true
})

VeeValidate.defineRule('reqAtLeastOne', (value) => {
  if (!value || !value.length) {
    return m.reqAtLeastOne
  }
  return true
})

VeeValidate.defineRule('isNumber', (value) => {
  if (value && isNaN(parseInt(value))) {
    return m.number
  }
  return true
})

VeeValidate.defineRule('reqNumber', (value) => {
  if (!value || value < 0.01) {
    return m.reqNumber
  }
  return true
})

VeeValidate.defineRule('reqNumberWithZero', (value) => {
  if (value === 0) {
    return true
  }
  if (!value || parseFloat(value) < 0) {
    return m.reqNumber
  }
  return true
})

VeeValidate.defineRule('reqFile', (value) => {
  if (!value) {
    return m.reqFile
  }
  return true
})

VeeValidate.defineRule('ccard', (value, [target], ctx) => {
  const cardLengthRules = {
    VISA: [13, 16, 19],
    VIELECTRON: [13, 16, 19],
    MASTERCARD: [16],
    DINERS: [14, 16, 19],
    AMEX: [15],
    default: [12, 13, 14, 15, 16, 17, 18, 19],
  }

  const cardRules = {
    VISA: /^4\d*$/,
    VIELECTRON: /^4\d*$/,
    MASTERCARD: /^5[1-5]\d*$/,
    DINERS: /(?:^30[0-5]?|^3[689]|^5[45])\d*$/,
    AMEX: /^3[47]\d*$/,
    default: /^\d+$/,
  }

  if (
    !(cardRules[ctx.form.cardType] ?? cardRules.default).test(value) ||
    !(cardLengthRules[ctx.form.cardType] ?? cardLengthRules.default).includes(value.length)
  ) return m.ccard

  let arr = value.split('').reverse()
  let sum = 0
  const control = parseInt(arr.splice(0, 1)[0])

  arr = arr.forEach((v, i) => {
    sum += i % 2 === 0 ? Array.from(String(parseInt(v) * 2), Number).reduce((p, a) => p + a, 0) : parseInt(v)
  })

  if ((10 - sum % 10) % 10 !== control) return m.ccard

  return true
})

VeeValidate.defineRule('cvv', (value, [target], ctx) => {
  const cardCvvRules = {
    DINERS: /^[0-9]{3,4}$/,
    AMEX: /^[0-9]{3,4}$/,
    default: /^[0-9]{3}$/,
  }

  if (!(cardCvvRules[ctx.form.cardType] ?? cardCvvRules.default).test(value)) {
    return ['DINERS', 'AMEX'].includes(ctx.form.cardType) ? m.cvv34 : m.cvv
  }
  return true
})

VeeValidate.defineRule('positiveNumberIfOtherFieldZero', (value, [target], ctx) => {
  const isNr = (nr) => !isNaN(parseInt(nr))
  const isPositiveNr = (nr) => nr && parseInt(nr) > 0

  if (value === undefined || value === '') return m.required
  if (!isNr(value)) return m.number
  if (value === 0 && !isPositiveNr(ctx.form[target])) return m.onePositive
  return true
})
