/* global Vue */
import { useCart } from '../../use-cart'
import RatePlan from './RatePlan'
import UnitImage from './UnitImage'

export default {
  name: 'UnitAvailable',
  components: { RatePlan, UnitImage },
  template: `
<div class="unit-card" :id="'unit-'+ unit.id" ref="containerRef">
  <div class="unit-card__container">
    <UnitImage v-if="unit.image" :unit="unit" />
    <img v-else src="https://user-images.githubusercontent.com/5190707/149798429-0a048f44-46cc-49f0-ada6-6d35a1ac4511.png" alt="No unit image" />

    <div class="unit-card__content">
      <h3>{{ unit.name }} <span class="debug">[{{ unit.id }}]</span></h3>
      <p v-if="unit.description" class="unit-card__description" v-html="unit.description"></p>

      <div class="unit-card__icons">
        <div v-show="unit.area">
          <i class="icon-area"></i><span>{{ unit.area }} m<sup>2</sup></span>
        </div>
        <div>
          <i v-for="i in unit.occupancyDisplayAdult" class="icon-adult"></i>
          <i v-for="i in unit.occupancyDisplayChildren" class="icon-child"></i>
        </div>
      </div>
      <hr>

      <div class="unit-card__rates">
        <p>{{ $t('unitAvailable.startingFrom') }}</p>
        <p>
          <div>
            <span v-show="unit.lowestMember">{{ $t('unitAvailable.regular') }}</span><i>{{ unit.lowestRegular.display }}</i>
          </div>
          <div v-show="unit.lowestMember">
            <span>{{ $t('unitAvailable.member') }}</span><i class="unit-card__big-rate">{{ unit.lowestMember?.display }}</i>
          </div>
        </p>
        <div class="unit-card__button">
          <button class="button button--outline" :class="showRates ? 'is-open' : ''" @click="toggleRates()" :disabled="!!cart.rateId.value || ratesDisabledMessage">
            <span v-show="showRates">{{ $t('unitAvailable.hideRates') }}</span>
            <span v-show="!showRates">{{ $t('unitAvailable.selectRate') }}</span>
          </button>
          <div class="warning" v-if="availableUnits < 5 && availableUnits > 0"><span class="icon-warning"></span>{{ $t('unitAvailable.nrUnitsAvailable', availableUnits) }}</div>
        </div>
      </div>
    </div>

  </div>
  <div v-if="areRatesCalculated && !onlyOnDemand && !ratesDisabledMessage" v-show="showRates || !!cart.rateId.value" class="rateplan-list">
    <RatePlan
      v-for="ratePair in filteredRates"
      @addToCart="e => $emit('addToCart', e)"
      @onlyOnDemand="onOnlyOnDemand"
      :key="ratePair.regular.id"
      :ratePair="ratePair"
      :unit="unit"
      :isMember="isMember"
      :nights="nights"
      :extras="extras"
      :maxChildAge="maxChildAge"
    />
  </div>
  <div v-if="areRatesCalculated && onlyOnDemand === 'notEnoughAdults'" class="unit-card__footer">
    <p class="unit-card__note">{{ $t('unitDemand.contactTextAdults') }}</p>
    <div>
      <a class="button button--outline" :href="contactURL + '&subject=' + unit.name">{{ $t('unitDemand.contactButton') }}</a>
      <button class="button button--outline" @click="scrollToTop">{{ $t('unitDemand.modifySearch') }}</button>
    </div>
  </div>
  <div v-if="ratesDisabledMessage" class="unit-card__warning">
    <div>
      <i v-if="ratesDisabledMessage.icon" :class="ratesDisabledMessage.icon"></i>
      <p class="no-margin" v-html="ratesDisabledMessage.text"></p>
    </div>
  </div>
</div>
  `,
  props: {
    rates: Array,
    unit: Object,
    maxChildAge: Number,
    isMember: Boolean,
    nights: Number,
    selectedUnits: Array,
    extras: String,
    contactURL: String,
  },
  emits: ['addToCart', 'modifyGuests'],
  setup ({ unit, contactURL }, { emit }) {
    const { cart } = useCart()
    const { t } = VueI18n.useI18n()
    const showRates = Vue.ref(false)
    const onlyOnDemand = Vue.ref(null)
    const containerRef = Vue.ref(null)
    const areRatesCalculated = Vue.ref(!!cart.rateId)
    const availableUnits = Vue.computed(() => unit.availableUnits - cart.selectedUnits.value.filter(u => u.id === unit.id).length)

    const filteredRates = Vue.computed(() => unit.rates.filter(rate => {
      return !cart.rateId.value || rate.member?.id === cart.rateId.value || rate.regular.id === cart.rateId.value
    }))

    const ratesDisabledMessage = Vue.computed(() => {
      if (cart.minOccupancyRemaining.value == 0) {
        return {
          icon: 'icon-check',
          text: t('unitAvailable.sufficientUnitsAdded'),
        }
      }

      const icon = 'icon-warning'
      if (cart.unitLimitReached.value) {
        return {
          icon,
          text: t('unitAvailable.unitLimitReached', { url: contactURL + '&subject=' + unit.name }),
        }
      }
      if (availableUnits.value === 0) {
        return {
          icon,
          text: t('unitAvailable.noMoreUnits'),
        }
      }
      if (unit.occupancyMin > cart.minOccupancyRemaining.value) {
        return {
          icon,
          text: t('unitAvailable.minimumOccupancyRequired'),
        }
      }
      if (filteredRates.value.length == 0) {
        return {
          icon: null,
          text: t('unitAvailable.unavailableRatePlan')
        }
      }

      return false
    })

    function toggleRates() {
      if (!areRatesCalculated.value) { areRatesCalculated.value = true }
      showRates.value = !showRates.value
    }

    function scrollToTop() {
      window.scrollTo(0,0)
    }

    function onOnlyOnDemand(e) {
      console.log('onOnlyOnDemand', e);
      onlyOnDemand.value = e
    }

    function changeGuests(e) {
      e.preventDefault()
      emit('modifyGuests')
      return false
    }

    Vue.watch(ratesDisabledMessage, () => {
      Vue.nextTick(() => {
        const button = containerRef.value?.querySelector('.add-guests');
        if (button) {
          button.addEventListener('click', changeGuests);
        }
      })
    }, { immediate: true })

    return {
      WWW_HOME_URL,
      PREFIX,
      containerRef,
      scrollToTop,
      onOnlyOnDemand,
      onlyOnDemand,
      showRates,
      areRatesCalculated,
      toggleRates,
      filteredRates,
      cart,
      availableUnits,
      ratesDisabledMessage,
    }
  }
}
