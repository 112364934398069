/* global Vue, WWW_HOME_URL, HOME_TITLE, LOGO, CONTEXT, LOCALE, PAGE_404 */

import { useState } from './Notifications'
import SimpleHeader from './SimpleHeader'

export default {
  name: 'BookingError',
  components: { SimpleHeader },
  template: `
  <teleport to="body">
    <div v-if="state.fatalError.value" class="fatal-error">

      <SimpleHeader showPhone="true" />

      <div class="container grid section-margin-bottom">
        <div class="col-12 col-sm-10 col-lg-8 offset-xl-1 offset-xxl-2 page-error">
          <h1 class="h4" v-if="error && $t('fatalError.code.' + error.code) != 'fatalError.code.' + error.code">{{ $t('fatalError.code.' + error.code) }}</h1>
          <h1 class="h4" v-else>{{ $t('fatalError.title') }}</h1>
          <hr>
          <p class="large-text">{{ $t('fatalError.message') }}</p>
          <p v-if="error.code">Error code: {{ error.code }}</p>

          <div
            v-if="isDebugEnabled || (CONTEXT !== 'production')"
            class="col-12 fatal-error__details">
            <p v-if="(typeof state.fatalError.value === 'string')">{{ state.fatalError.value }}</p>
            <p v-else v-for="p in Object.getOwnPropertyNames(state.fatalError.value)">{{ p }} : {{ state.fatalError.value[p] }}</p>
          </div>

          <nav>
            <a
              class="large-text"
              :href="getFullUrl('/' + LOCALE)">
              {{ $t('homepage') }}
            </a>
            <a
              v-for="(link, index) in PAGE_404.helpfulLinks.slice(0, -2)"
              :key="index"
              class="large-text"
              :href="getFullUrl(link.href)">
              {{ link.text }}
            </a>
          </nav>
        </div>
      </div>
    </div>
  </teleport>
  `,
  setup () {
    const { state } = useState()
    const isDebugEnabled = Vue.ref(JSON.parse(window.localStorage.getItem('isDebugEnabled')))

    function getFullUrl(url) {
      return `${WWW_HOME_URL.replace(/\/+$/, '')}${url}`
    }

    const loaderSvg = document.getElementById('loaderSvg')
    if (loaderSvg) {
      Vue.watch(() => state.isLoading, isLoading => {
        if (isLoading) {
          loaderSvg.classList.remove('is-hidden')
        } else {
          loaderSvg.classList.add('is-hidden')
        }
      })
    }

    const error = state.fatalError

    return {
      state,
      error,
      isDebugEnabled,
      HOME_TITLE,
      LOGO,
      CONTEXT,
      LOCALE,
      PAGE_404,
      getFullUrl,
    }
  },
}
