export default {
  name: 'BookingAccessCode',
  template: `
    <label for="access-code">{{ $t('bookingModal.accessCode') }}</label>
    <div class="access-code-input">
      <input id="access-code" :placeholder="$t('bookingModal.accessCode')" type="text" v-model.lazy="bookingParams.accessCode">
      <button @click="bookingParams.accessCode = ''"><i class="icon-close"></i></button>
    </div>
    <div v-show="!isAccessCodeValid && !accessCodeWarning">{{ $t('bookingModal.invalidAccessCode') }}</div>
    <div v-show="accessCodeRemovedInfo">{{ $t('bookingModal.accessCodeRemoved') }}</div>
  `,
  props: {
    isAccessCodeValid: Boolean,
    accessCodeRemovedInfo: Boolean,
    bookingParams: Object,
  },
}
