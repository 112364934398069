/* global Vue, CLD_URL, CLD_TRANSFORM, CONTEXT, CANCELLATION_URL, LOCALE, WWW_HOME_URL, PREFIX, DATA_LAYER */

import { useCart, clearCart, resetBookingNumber } from '../../use-cart'
import RatePolicyModal from '../components/RatePolicyModal'
import SeeYouSoon from '../components/SeeYouSoon'
import LoyaltyBenefits from '../components/LoyaltyBenefits'

export default {
  name: 'Step5',
  components: { RatePolicyModal, LoyaltyBenefits, SeeYouSoon },
  template: `
  <div class="step-5">
<div v-if="$route.params.reservationId?.length" class="negative-cp-sm-down">
  <div v-if="property.image" class="hide-on-print">
    <img
      v-if="property.image.includes(CLD_URL)"
      :src="CLD_URL + 'w_1184,h_666,' + CLD_TRANSFORM + '/' + property.image.replace(CLD_URL, '')"
      :alt="property.title"
      style="display: block;">
    <img
      v-else
      :src="property.image"
      :alt="property.title"
      style="display: block;">
  </div>

  <div class="hide-on-screen">
    <h3 class="no-margin-top">
      {{ property.title }}
      <span class="stars">
        <i v-for="n in property.stars" class="icon-star"></i>
      </span>
    </h3>
  </div>

  <div class="step-5__cards grid no-gap no-row-gap">
    <div class="col-12 card-border card-border--code no-margin">
      <div class="card-border__wrapper">
        <div class="card-border__content">
          <h3 class="no-margin">{{ $t('stepFive.reservationCode') }}</h3>
          <div>
            <div class="h3">{{ $route.params.reservationId }}</div>
            <p class="no-margin">
              {{ $t('stepFive.requestReceived') }}<br>
              {{ $t('stepFive.codeSent') }} {{ cart.client.value.email }}
            </p>
          </div>
          <span class="rateplan__policy" @click="showRatePolicy = true">{{ $t('ratePlan.ratePolicy') }}</span>
        </div>
      </div>
    </div>

    <div class="col-12 col-print-6 col-lg-6 card-border card-border--no-padding-mobile no-margin">
      <div class="card-border__wrapper">
        <div class="card-border__content">
          <h3 class="no-margin">{{ $t('stepFive.reservationHolder') }}</h3>
          <hr>
          {{ $t('stepFive.firstName') }}: <strong><span data-sl="mask">{{ cart.client.value.firstName }}fds</span></strong><br>
          {{ $t('stepFive.lastName') }}: <strong><span data-sl="mask">{{ cart.client.value.lastName }}</span></strong><br>
          {{ $t('stepFive.email') }}: <strong>{{ cart.client.value.email }}</strong><br>
          {{ $t('stepFive.phoneNumber') }}: <strong>{{ cart.client.value.mobile }}</strong>
        </div>
      </div>
    </div>

    <div class="col-12 col-print-6 col-lg-6 card-border card-border--no-padding-mobile no-margin">
      <div class="card-border__wrapper">
        <div class="card-border__content">
          <h3 class="no-margin">{{ $t('stepFive.guarantee') }}</h3>
          <hr>
          <div v-if="cart.payment.value.method.type === 'CCARD_PAYMENT' || cart.payment.value.method.type === 'CCARD_GUARANTEE'">
            {{ $t('stepFive.cardType') }}: <strong>{{ cart.payment.value.cardType }}</strong><br>
            {{ $t('stepFive.cardNumber') }}: <strong>{{ cart.payment.value.maskedCardNumber }}</strong><br>
            {{ $t('stepFive.expirationDate') }}: <strong>{{ cart.payment.value.validMonth }}/{{ cart.payment.value.validYear }}</strong><br>
            {{ $t('stepFive.nameCard') }}: <strong><span data-sl="mask">{{ cart.payment.value.firstName }} {{ cart.payment.value.lastName }}</span></strong><br>
          </div>
          <div v-else-if="cart.payment.value.method.type === 'NOGUARANTEE'">
            <h3>{{ $t('stepFive.noGuarantee') }}</h3>
            <div v-html="$route.params.guaranteeConditions"></div>
          </div>
          <div v-else>
            <div v-html="$route.params.guaranteeConditions"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <teleport to="#step5-buttons">
    <div class="grid negative-cp-sm-down" style="--grid-row-gap: 8px;">
      <button @click="printPage" class="col-12 col-md-4 button button--beige button--icons">
        {{ $t('stepFive.printPage') }}
        <i class="icon-arrow-right"></i>
      </button>
      <a :href="bookAnotherURL" class="col-12 col-md-4 button button--beige button--icons">
        {{ $t('stepFive.bookAnother') }}
        <i class="icon-arrow-right"></i>
      </a>
      <a :href="cancellationURL" target="_blank" class="col-12 col-md-4 button button--beige button--icons">
        {{ $t('stepFive.cancelReservation') }}
        <i class="icon-arrow-right"></i>
      </a>
    </div>

    <LoyaltyBenefits :totalPrice="priceQuote.grandTotal.price" :email="cart.client.value.email" />
  </teleport>

  <teleport to="#step5-bottom">
    <SeeYouSoon />
  </teleport>
</div>

<div v-else class="hide-on-print">
  <h2>{{ $t('stepFive.mistakeVisits') }}</h2>
  <p>{{ $t('stepFive.checkReservation') }}</p>
</div>

<RatePolicyModal :isOpened="showRatePolicy" :rateId="priceQuote?.rateId" @success="showRatePolicy = false" />
</div>
`,
  props: ['property', 'cart', 'priceQuote'],

  setup (props) {
    const { cart } = useCart()
    const showRatePolicy = Vue.ref(false)
    const { propertyId, date, nights, accessCode } = cart.getSerializedForAvailability()

    Vue.onMounted(() => {
      document.querySelector('h1').scrollIntoView()
      const reservationId = VueRouter.useRouter().currentRoute.value.params.reservationId
      DATA_LAYER.bookingPurchase({
        cart,
        priceQuote: props.priceQuote,
        property: props.property,
        reservationId: reservationId,
      })

      if (CONTEXT === 'production') {
        clearCart()
      } else {
        resetBookingNumber()
      }
    })

    const printPage = () => window.print()

    const bookAnotherURL = `${PREFIX}/step1/?propertyId=${propertyId}&date=${date}&nights=${nights}` + (accessCode != '' ? `&accessCode=${accessCode}` : '')
    const cancellationURL = CANCELLATION_URL.replace('{LOCALE}', LOCALE)

    return {
      printPage,
      cart,
      showRatePolicy,
      cancellationURL,
      bookAnotherURL,
      CLD_URL,
      CLD_TRANSFORM,
      WWW_HOME_URL,
      PREFIX
    }
  }
}
