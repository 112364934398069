/* global VueI18n, LOCALE */

import { datetimeFormats, messagesCommon } from './translations-common'
import { mergeDeep } from '../utils-common'

const CreateI18n = (messagesSpecific) => {
  return VueI18n.createI18n({
    legacy: false,
    locale: LOCALE,
    datetimeFormats,
    messages: mergeDeep(messagesCommon, messagesSpecific),
    warnHtmlMessage: false,
    pluralRules: {
      si: (choice, choicesLength) => {
        if (choice <= 3) {
          return Math.min(choice, choicesLength - 1)
        }

        if (choice === 4 && choicesLength >= 4) {
          return 3
        }

        return Math.min(4, choicesLength - 1)
      },
    },
  })
}

export { CreateI18n }
