/* global Vue */

export default {
  name: 'PhoneNumber',
  template: `
    <label :for="id">{{ label }}</label>
    <input :id="id" type="tel" v-model="localPhoneNumber">
  `,
  props: { countryPrefix: String, phoneNumber: String, id: String, label: String },
  emits: ['phoneNumberChanged'],
  setup (props, { emit }) {
    const localPhoneNumber = Vue.ref('')
    const { countryPrefix, phoneNumber } = Vue.toRefs(props)

    Vue.watch(countryPrefix, (currentValue, oldValue) => {
      if (oldValue === '' && localPhoneNumber.value != null) return
      if (localPhoneNumber.value) {
        localPhoneNumber.value = localPhoneNumber.value.replace(oldValue, currentValue)
      } else {
        localPhoneNumber.value = currentValue
      }
    }, { deep: true })

    Vue.watch(phoneNumber, (currentValue) => {
      localPhoneNumber.value = currentValue
    }, { deep: true })

    Vue.watch(localPhoneNumber, (currentValue) => {
      if (currentValue != null) {
        emit('phoneNumberChanged', currentValue)
      }
    }, { deep: true })

    Vue.onMounted(async () => { localPhoneNumber.value = props.phoneNumber })

    return { localPhoneNumber }
  },
}
