/* global Vue, PREFIX, IS_BOOKING */

import Modal from './Modal'
import { LoyaltyProfile, useLoyaltyProfile } from '../../loyalty/components/LoyaltyProfile'
import { startLoading, setErrorAndStopLoading, stopLoading } from './Notifications'
import AuthService from '../../AuthService'
import { useAuthObserver } from '../../use-auth-observer'

export default {
  name: 'AuthStatus',
  components: { Modal, LoyaltyProfile },
  template: `
    <div class="header__profile">
      <button
        v-if="!isAuthenticated && !isLoading"
        @click="e => { startLoading('authStatus'); $AuthService.signIn() } "
        class="header__icon header__icon--user"
        :title="$t('authStatus.login')">
        <i class="icon-user"></i>
      </button>

      <button
        v-else-if="isAuthenticated && !isLoading"
        @click="showMenu = !showMenu"
        class="header__icon header__icon--user"
        ref="loyaltyIcon"
        :title="showMenu ? $t('authStatus.closeMenu') : $t('authStatus.openMenu')">
        <span class="hide-xs-up" data-sl="mask">{{ adjustedDisplayName('initials') }}</span>
        <span class="hide-xs-down hide-md-up" data-sl="mask">{{ adjustedDisplayName('short') }}</span>
        <span class="hide-md-down" data-sl="mask">{{ adjustedDisplayName('full') }}</span>
        <i :class="showMenu ? 'active' : ''" class="icon-caret-down"></i>
      </button>

      <div v-else class="header__icons--dots">&nbsp;</div>

      <transition name="fade">
        <nav v-show="showMenu" class="header__loyalty-nav" ref="loyaltyNav">
          <template v-if="hasLoyaltyProfile && !IS_BOOKING">
            <a :href="PREFIX + '/loyalty/account/#'" @click="showMenu = false">{{ $t('authStatus.dashboard') }}</a>
            <a :href="PREFIX + '/loyalty/account/#/reservations'" @click="showMenu = false">{{ $t('authStatus.myBookings') }}</a>
            <a :href="PREFIX + '/loyalty/account/#/activity'" @click="showMenu = false">{{ $t('authStatus.pointsActivity') }}</a>
            <a :href="PREFIX + '/loyalty/account/#/awards'" @click="showMenu = false">{{ $t('authStatus.MaiStarRewards') }}</a>
            <a :href="PREFIX + '/loyalty/account/#/profile'" @click="showMenu = false">{{ $t('authStatus.myProfile') }}</a>
          </template>
          <template v-else-if="!IS_BOOKING">
            <button @click="completeRegistration()">{{ $t('authStatus.completeRegistration') }}</button>
          </template>
          <hr v-if="hasLoyaltyProfile && !IS_BOOKING">
          <button @click="$AuthService.signOut()">{{ $t('authStatus.logout') }}</button>
        </nav>
      </transition>
    </div>

    <teleport to="body">
      <Modal v-if="isAuthenticated" :isOpen="isModalOpen" @closeModal="isModalOpen = false" :title="$t('loyaltyInvite.title')">
        <LoyaltyProfile v-if="profile" :profile="profile" :registration="true" @success="isModalOpen = false" />
      </Modal>
    </teleport>
  `,
  setup (props, { emit }) {
    const isLoading = Vue.ref(true)
    const { profile } = useLoyaltyProfile()
    const isModalOpen = Vue.ref(false)
    const showMenu = Vue.ref(false)
    const displayName = Vue.ref('')
    const loyaltyId = Vue.ref(null)
    const loyaltyIcon = Vue.ref(null)
    const loyaltyNav = Vue.ref(null)
    const { authObserver } = useAuthObserver()

    const isAuthenticated = Vue.ref(false)
    const hasLoyaltyProfile = Vue.computed(() => loyaltyId.value !== null)

    function adjustedDisplayName (length) {
      switch (length) {
        case 'initials':
          return displayName.value.split(' ').map(word => word[0]).join('')
        case 'short':
          return displayName.value.split(' ')[0]
        default:
          return displayName.value
      }
    }

    function completeRegistration () {
      isModalOpen.value = true
      showMenu.value = false
    }

    Vue.watch(showMenu, (show) => {
      if (show && window.matchMedia('(max-width: 768px)').matches) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    })

    document.addEventListener('click', (event) => {
      if (
        showMenu.value &&
        !loyaltyIcon.value.contains(event.target) &&
        !loyaltyNav.value.contains(event.target)
      ) {
        showMenu.value = false
      }
    }, { passive: true })

    Vue.onMounted(() => {
      AuthService.on('ended', e => { isLoading.value = false })
      if (AuthService.isFinished) {
        console.warn('AuthService.isFinished')
        isLoading.value = false
      }
      AuthService.on('error', e => {
        console.error(e)
        isLoading.value = false
        setErrorAndStopLoading(e.detail)
      })
      AuthService.on('authenticated', e => {
        console.warn('authenticated', e)
        displayName.value = e.detail.displayName
        isLoading.value = false
        isAuthenticated.value = true
        if (!Number.isInteger(e.detail.loyaltyId)) {
          profile.firstName = AuthService.firstName
          profile.lastName = AuthService.lastName
          profile.email = AuthService.email
          if (!IS_BOOKING) completeRegistration()
        } else {
          loyaltyId.value = e.detail.loyaltyId
        }

        document.body.dataset.authentication = JSON.stringify(e.detail)
      })
      if (AuthService.isAuthenticated) {
        console.warn('AuthService.isAuthenticated')
        isAuthenticated.value = true
        displayName.value = AuthService.getDisplayName()
        isLoading.value = false

        document.body.dataset.authentication = JSON.stringify({
          displayName: AuthService.getDisplayName(),
          accountId: AuthService.accountId,
          loyaltyId: AuthService.loyaltyId,
        })
      }
    })

    Vue.watch(authObserver.signInRequested, () => {
      AuthService.signIn()
    })

    window.onpageshow = (event) => {
      if (event.persisted) {
        stopLoading('authStatus')
        console.warn('AuthService.clearBrowserStorage()')
        AuthService.clearBrowserStorage()
      }
    }

    return {
      PREFIX,
      IS_BOOKING,
      isLoading,
      profile,
      isAuthenticated,
      hasLoyaltyProfile,
      isModalOpen,
      showMenu,
      loyaltyIcon,
      loyaltyNav,
      startLoading,
      completeRegistration,
      adjustedDisplayName,
    }
  },
}
