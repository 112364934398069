const isEmpty = (dataObject) => {
  if (typeof dataObject !== 'object') return true
  return (Object.getOwnPropertyNames(dataObject).length === 0)
}

const clone = (o) =>
  typeof o === 'object' && o !== null ?
  (Array.isArray(o) ?
    o.map(e => clone(e)) :
    Object.keys(o).reduce(
      (r, k) => (r[k] = clone(o[k]), r), {}
    )
  ) : o

export {
  isEmpty,
  clone,
}
