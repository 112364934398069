/* global Vue, IS_BOOKING */

import { ErrorLevel } from '../../classes/Errors'
import Notification from './Notification'
import FatalError from './FatalError'

const Notifications = {
  name: 'Notifications',
  components: { Notification, FatalError },
  template: `
  <teleport to="body">
    <div class="notifications">
      <Notification
        v-for="(notification, i) in state.notifications.value"
        :type="notification.type"
        :title="notification.title"
        :details="notification.details"
        :key="i"
        @close="closeNotification(i)" />
    </div>

    <FatalError v-if="state.fatalError.value" :error="state.fatalError.value" />
  </teleport>
  `,
  setup (props) {
    const { state } = useState()

    const loaderSvg = document.getElementById('loaderSvg')
    if (loaderSvg) {
      Vue.watch(() => _state.isLoading, isLoading => {
        if (isLoading) {
          loaderSvg.classList.remove('is-hidden')
        } else {
          loaderSvg.classList.add('is-hidden')
        }
      })
    }

    return {
      state,
      closeNotification,
    }
  },
}

const _state = Vue.reactive({
  notifications: [],
  processes: new Set(),
  fatalError: null,
  isLoading: false,
  isSeen: true,
})

function closeNotification (i) {
  _state.notifications.splice(i, 1)
}

function startLoading (subsystem) {
  if (!subsystem.length) {
    throw TypeError('subsystem must be a string')
  }
  _state.processes.add(subsystem)
  _state.isLoading = _state.processes.size > 0
}

function stopLoading (subsystem) {
  if (!subsystem.length) {
    throw TypeError('subsystem must be a string')
  }
  _state.processes.delete(subsystem)
  setTimeout(() => { _state.isLoading = _state.processes.size > 0 }, 500)
}

function setSuccessAndStopLoading (s, isLocal = false) {
  _state.isLoading = false
  _state.processes.clear()
  _state.notifications.push({ type: 'success', details: s })
  if (_state.notifications.length > 3) _state.notifications.shift()
  if (!isLocal) { // called from this file
    _state.isSeen = false
  }
}

function setErrorAndStopLoading (err, isLocal = false) {
  console.error(err)
  window.Sentry?.captureException(err)
  _state.isLoading = false
  _state.processes.clear()

  if (err?.level === ErrorLevel.FATAL || IS_BOOKING) {
    _state.fatalError = err
    window.sessionStorage.removeItem('cart')
    _state.notifications = []
    document.body.classList.add('overflow-hidden')
  } else {
    _state.notifications.push({ type: err.level || 'error', details: err })
    if (_state.notifications.length > 3) _state.notifications.shift()
  }

  if (!isLocal) { // called from this file
    _state.isSeen = false
  }
}

async function runAsync (f, onlyOnce = false) {
  if (typeof f !== 'function') {
    throw new TypeError('not a function')
  }
  if (!f?.name?.length) {
    throw new TypeError('can only be used with named functions')
  }
  if (onlyOnce && _state.processes.has(f.name)) {
    return
  }
  startLoading(f.name)
  try {
    await f()
  } catch (e) {
    setErrorAndStopLoading(e)
  }
  stopLoading(f.name)
}

function useState () {
  return {
    state: {
      notifications: Vue.computed(() => _state.notifications),
      fatalError: Vue.computed(() => _state.fatalError),
      isLoading: Vue.computed(() => _state.isLoading),
      processes: Vue.computed(() => Array.from(_state.processes)),
      isSeen: Vue.computed(() => _state.isSeen),
    },
  }
}

export {
  Notifications,
  useState,
  startLoading,
  stopLoading,
  setSuccessAndStopLoading,
  setErrorAndStopLoading,
  runAsync,
}
