/* global Vue, CLD_URL */

import { lazyload, responsive, HtmlImageLayer } from '@cloudinary/html'
import { CloudinaryImage } from '@cloudinary/url-gen'
import { fill } from '@cloudinary/url-gen/actions/resize'
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity'

export default {
  name: 'CldImg',
  template: `
    <img ref="imgTag">
  `,
  props: { src: String, setHeight: Boolean, preload: Boolean },
  setup (props) {
    const imgTag = Vue.ref(null)
    const hil = Vue.ref(null)

    function getCldImage (src) {
      const relSrc = decodeURI(src.replace(CLD_URL, ''))

      if (props.setHeight) {
        const imgHeight = Math.ceil(imgTag.value.clientHeight / 100) * 100
        const imgWidth = Math.ceil(imgTag.value.clientWidth / 200) * 200

        return new CloudinaryImage(relSrc, { cloudName: 'maistra' })
          .resize(fill().width(imgWidth).height(imgHeight).gravity(autoGravity()))
          .quality('auto:best').format('auto')
      } else {
        return new CloudinaryImage(relSrc, { cloudName: 'maistra' })
          .quality('auto:best').format('auto')
      }
    }

    const plugins = props.preload ? [] : [lazyload()]
    if (!props.setHeight) plugins.push(responsive({ steps: 200 }))

    Vue.nextTick(() => {
      hil.value = new HtmlImageLayer(imgTag.value, getCldImage(props.src), plugins)
    })

    Vue.watch(() => props.src, (newSrc) => {
      hil.value = new HtmlImageLayer(imgTag.value, getCldImage(newSrc), plugins)
    })

    return { imgTag }
  },
}
