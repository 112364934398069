/* global Vue, AZURE_URI, LOCALE */

import { getDescRatePlan } from '../../api'
import { setErrorAndStopLoading } from './Notifications'
import Modal from './Modal'

export default {
  name: 'RatePolicyModal',
  components: { Modal },
  template: `
<Modal :isOpen="isOpened" @closeModal="$emit('success')" :title="$t('ratePlan.ratePolicy')">
  <ul>
    <li v-for="policy in policies">{{ policy }}</li>
    <li v-for="info in extraInformation">{{ info }}</li>
  </ul>
</Modal>
`,
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    rateId: {
      type: String,
      required: true,
    },
    extraInformation: {
      type: Array,
    },
  },
  emits: ['success'],
  setup (props, { emit }) {
    const policies = Vue.ref(null)

    async function fetchRatePlan() {
      try {
        const json = await getDescRatePlan(props.rateId)
        policies.value = json.RatePlanDesc.RatePolicy.Policy
      } catch (err) {
        setErrorAndStopLoading(err)
      }
    }

    Vue.watch(() => props.isOpened, async (currentValue) => {
      if (currentValue && !policies.value) {
        // fetch data
        await fetchRatePlan()
      }
    })

    return { policies }
  }
}
