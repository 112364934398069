/* global Vue, VeeValidate, VueI18n, DATA_LAYER */

import { clone, isEmpty } from '../../utils'
import { AppError, ErrorLevel } from '../../classes/Errors'
import { runAsync, setErrorAndStopLoading } from '../components/Notifications'
import { useCart, setPayment, maskCardNumber, resetBookingNumber } from '../../use-cart'
import * as Api from '../../api'
import Modal from '../components/Modal'
import RatePolicyModal from '../components/RatePolicyModal'
import AuthService from '../../AuthService'

export default {
  name: 'Step4',
  components: { Modal, RatePolicyModal },
  template: `<div>
  <div class="card-border step-4 no-margin-top">
    <div class="card-border__wrapper">
      <div class="card-border__content">
        <h3 class="no-margin">{{ $t('stepFour.reservationGuarantee') }}</h3>
        <hr>

        <form class="grid" ref="formElement">
          <div class="col-12 col-lg-10 col-xl-6" v-if="priceQuote.guaranteeMethods.length > 1">
            <label for="guaranteeMethod">{{ $t('stepFour.guaranteeMethod') }}</label>
            <select class="select-input" id="guaranteeMethod" v-model="localPayment.method">
              <option v-for="method in priceQuote.guaranteeMethods" :value="method">{{ $t('stepFour.' + method.type) }}</option>
            </select>
            <span class="field-error">{{ formErrors.guaranteeType }}</span>
          </div>

          <div v-if="guaranteeMethodType === 'NOGUARANTEE'" class="col-12">
            <p>{{ $t('stepFour.noGuarantee') }}</p>
            <p>{{ $t('stepFour.additionalText') }}</p>
            <p>{{ $t('stepFour.extraText') }}</p>
          </div>

          <div v-if="guaranteeMethodType === 'BANKTRANSFER'" class="col-12">
            <p>{{ guaranteeMethod.text }}</p>
          </div>

          <div v-if="guaranteeMethodType === 'CCARD_PAYMENT' || guaranteeMethodType === 'CCARD_GUARANTEE'" class="col-12 grid">
            <div class="col-12 col-lg-10 col-xl-6">
              <label for="cardType">{{ $t('stepFour.cardType') }}</label>
              <select class="select-input" id="cardType" v-model="localPayment.cardType">
                <option v-for="card in localPayment.method.paymentMethods" :value="card.creditCardType">{{ $t('stepFour.' + card.creditCardType) }}
                </option>
              </select>
              <span class="field-error">{{ formErrors.cardType }}</span>
            </div>
            <div class="hide-sm-down col-lg-2 col-xl-6"></div>
            <div class="col-12 col-lg-10 col-xl-6">
              <label for="cardNumber">{{ $t('stepFour.cardNumber') }}</label>
              <div class="step-4__ccard-input">
                <input id="cardNumber" type="text" inputmode="numeric" pattern="\d*" v-model.trim="localPayment.cardNumber">
                <span class="step-4__ccard-input__ok" v-if="localPayment.cardNumber && !formErrors.cardNumber"></span>
              </div>
              <span class="field-error">{{ formErrors.cardNumber }}</span>
            </div>
            <div class="hide-sm-down col-lg-2 col-xl-6"></div>
            <div class="col-12 col-lg-3">
              <label for="validMonth">{{ $t('stepFour.expirationMonth') }}</label>
              <select class="select-input" id="validMonth" v-model.trim="localPayment.validMonth">
                <option v-for="n in 12" :value="n.toString()">{{ n }}</option>
              </select>
              <span class="field-error">{{ formErrors.validMonth }}</span>
            </div>

            <div class="col-12 col-lg-3">
              <label for="validYear">{{ $t('stepFour.expirationYear') }}</label>
              <select class="select-input" id="validYear" v-model.trim="localPayment.validYear">
                <option v-for="n in NO_OF_YEARS" :value="(n - 1  + currentYear).toString()">{{ n - 1  + currentYear }}</option>
              </select>
              <span class="field-error">{{ formErrors.validYear }}</span>
            </div>

            <div class="col-12 col-lg-3 cvv__field">
              <label for="cvv">{{ $t('stepFour.cvv') }}: </label>
              <input id="cvv" type="text" inputmode="numeric" pattern="\d*" v-model.trim="localPayment.cvv">
              <a href="javascript:;" @click="isModalOpen.cvvInfo = true" class="icon-info"></a>
              <span class="field-error">{{ formErrors.cvv }}</span>
            </div>

            <div class="col-12 col-lg-6">
              <label for="firstName">{{ $t('stepFour.firstName') }} </label>
              <input id="firstName" type="text" v-model="localPayment.firstName">
              <span class="field-error">{{ formErrors.firstName }}</span>
            </div>

            <div class="col-12 col-lg-6">
              <label for="lastName">{{ $t('stepFour.lastName') }}</label>
              <input id="lastName" type="text" v-model="localPayment.lastName">
              <span class="field-error">{{ formErrors.lastName }}</span>
            </div>
          </div>

          <div class="col-12">
            <label for="conditions" class="label-checkbox">
              <input id="conditions" type="checkbox" v-model="conditions">
              {{ $t('stepFour.fullAgreement') }}
              <a href="javascript:;" class="rateplan__policy" @click="showRatePolicy = true">{{ $t('ratePlan.salesPolicy') }}</a>
            </label>
            <span class="field-error">{{ conditionsError }}</span>
          </div>

          <p class="col-12 no-margin italic">{{ $t('formValidation.required') }}</p>

          <teleport to="#continue-button">
            <button @click.prevent="$emit('closeSummary'); onSubmitPayment()">{{ $t('stepFour.confirmBooking') }}</button>
          </teleport>
        </form>
      </div>
    </div>
  </div>

  <div>
    <h3>FAQ</h3>
    <hr />
    <p role="button" @click="modalPopupOpen('holder')">{{ $t('stepFour.faq.holder.cta') }}</p>
    <p role="button" @click="modalPopupOpen('payment')">{{ $t('stepFour.faq.payment.cta') }}</p>
    <p role="button" @click="modalPopupOpen('immigration')">{{ $t('stepFour.faq.immigration.cta') }}</p>
    <p role="button" @click="modalPopupOpen('confirmation')">{{ $t('stepFour.faq.confirmation.cta') }}</p>
  </div>

  <Modal :isOpen="isModalOpen.popups" @closeModal="isModalOpen.popups = false" :title="modalContent.title">
    <div class="grid">
      <div class="col-12">
        <p class="pre-line">{{ modalContent.description }}</p>
      </div>
    </div>
  </Modal>

  <Modal :isOpen="isModalOpen.cvvInfo" @closeModal="isModalOpen.cvvInfo = false" :title="$t('stepFour.cvvPopUp.title')" class="modal modal--cvv cvv">
    <div class="grid">
      <div class="col-9">
        <p class="cvv__description no-margin">{{ $t('stepFour.cvvPopUp.description') }}</p>
      </div>
      <div class="col-12 cvv__wrapper">
        <div class="cvv__left">
          <p>{{ $t('stepFour.cvvPopUp.paymentMethodsLeft') }}</p>
          <div class="cvv__payment-methods">
            <img src="https://res.cloudinary.com/maistra/image/upload/v1646405679/Assets/visa.png" alt="VISA" width="60" height="40">
            <img src="https://res.cloudinary.com/maistra/image/upload/v1646405679/Assets/mastercard.png" alt="MASTERCARD" width="60" height="40">
            <img src="https://res.cloudinary.com/maistra/image/upload/v1646405679/Assets/diners-club.png" alt="DINERS" width="60" height="40">
          </div>
          <div class="cvv__content">
            <p class="no-margin-top"><span>{{ $t('stepFour.cvvPopUp.back') }}</span>{{ $t('stepFour.cvvPopUp.backDescription') }}</p>
            <img src="https://res.cloudinary.com/maistra/image/upload/v1646405750/Assets/back-of-card.png" :alt="$t('stepFour.cvvPopUp.back')" width="183" height="103">
          </div>
        </div>
        <div class="cvv__right">
          <p>{{ $t('stepFour.cvvPopUp.paymentMethodsRight') }}</p>
          <div class="cvv__payment-methods">
            <img src="https://res.cloudinary.com/maistra/image/upload/v1646405679/Assets/american-express.png" alt="AMERICAN EXPRESS" width="60" height="40">
          </div>
          <div class="cvv__content">
            <p class="no-margin-top"><span>{{ $t('stepFour.cvvPopUp.front') }}</span>{{ $t('stepFour.cvvPopUp.frontDescription') }}</p>
            <img src="https://res.cloudinary.com/maistra/image/upload/v1646405750/Assets/front-of-card.png" :alt="$t('stepFour.cvvPopUp.front')" width="183" height="103">
          </div>
        </div>
      </div>
    </div>
  </Modal>

  <RatePolicyModal :isOpened="showRatePolicy" :rateId="priceQuote?.rateId" :extraInformation="extras ? [extras] : []" @success="showRatePolicy = false" />
</div>`,
  props: { priceQuote: Object, extras: String },
  emits: ['book'],
  setup (props) {
    const { t } = VueI18n.useI18n()
    const { cart } = useCart()
    const router = VueRouter.useRouter()

    const localPayment = Vue.reactive({})
    const formErrors = Vue.reactive({})
    const conditions = Vue.ref(false)
    const currentYear = new Date().getFullYear()
    const NO_OF_YEARS = 10
    const isModalOpen = Vue.reactive({
      agreement: false,
      popups: false,
    })
    const modalContent = Vue.reactive({
      title: '',
      description: '',
    })
    const showRatePolicy = Vue.ref(false)
    const formElement = Vue.ref(null)
    const localClient = Vue.reactive({})

    const modalPopupOpen = (type) => {
      modalContent.title = t(`stepFour.faq.${type}.title`)
      modalContent.description = t(`stepFour.faq.${type}.description`)
      isModalOpen.popups = true
    }

    const agreeCheck = () => !conditions.value ? t('mustAgree') : true

    const futureDate = () => {
      if (!localPayment.validMonth || !localPayment.validYear) {
        return t('bothRequired')
      }
      const today = new Date()
      const month = (today.getMonth() + 1)
      const year = (today.getFullYear())
      if (year < parseInt(localPayment.validYear)) {
        return true
      }
      if (month < parseInt(localPayment.validMonth)) {
        return true
      }
      return t('mustBeInFuture')
    }

    const guaranteeMethodType = Vue.computed(() => localPayment.method?.type)

    const guaranteeMethod = Vue.computed(() => props.priceQuote.guaranteeMethods.find(gm => gm.type === guaranteeMethodType.value))

    const validationSchema = Vue.computed(() => {
      if (guaranteeMethodType.value === 'BANKTRANSFER' || guaranteeMethodType.value === 'NOGUARANTEE') {
        return {
          conditions: agreeCheck
        }
      }
      const schema = {
        cardType: 'required',
        cardNumber: 'required|ccard',
        validMonth: futureDate,
        validYear: futureDate,
        cvv: 'required|cvv',
        firstName: 'required|min:1',
        lastName: 'required|min:1',
        conditions: agreeCheck
      }
      return schema
    })

    const { handleSubmit, errors, isSubmitting } = VeeValidate.useForm({ validationSchema })

    Vue.watch(isSubmitting, () => {
      if (Object.keys(errors).length > 0 && formElement.value) {
        const highlight = Array.from(formElement.value.querySelectorAll('.field-error')).filter(er => er.innerHTML)[0] || null
        if (highlight) { highlight.parentNode.scrollIntoView({ behavior: 'smooth' }) }
      }
    })

    const bookAsync = async () => {
      const cardNumber = localPayment.cardNumber
      if (cardNumber) {
        delete localPayment.cardNumber
        localPayment.maskedCardNumber = maskCardNumber(cardNumber)
      }
      setPayment(localPayment)
      if (CONTEXT !== 'production') {
        resetBookingNumber()
      }
      const serializedCart = cart.getSerializedForQuote()
      if (cardNumber) {
        serializedCart.payment.cardNumber = cardNumber
      }
      serializedCart.authStatus = AuthService.isAuthenticated
      serializedCart.authToken = AuthService.accessToken

      const booking = await Api.postBookingAsync(serializedCart) // throws error if unsucceful
      if (booking.Reservation) router.push({ name: 'step5', params: {
          reservationId: booking.Reservation.ReservationID['@Id'],
          guaranteeConditions: booking.Reservation.BookedRate.GuaranteeConditions.Policy.join("<br><br>")
        }
      })
    }

    const onSubmitPayment = handleSubmit(async () => await runAsync(bookAsync))

    const { errorMessage: conditionsError } = VeeValidate.useField('conditions')
    const { value: cardType, errorMessage: cardTypeError } = VeeValidate.useField('cardType')
    const { value: cardNumber, errorMessage: cardNumberError } = VeeValidate.useField('cardNumber')
    const { value: validMonth, errorMessage: validMonthError } = VeeValidate.useField('validMonth')
    const { value: validYear, errorMessage: validYearError } = VeeValidate.useField('validYear')
    const { value: cvv, errorMessage: cvvError } = VeeValidate.useField('cvv')
    const { value: firstName, errorMessage: firstNameError } = VeeValidate.useField('firstName')
    const { value: lastName, errorMessage: lastNameError } = VeeValidate.useField('lastName')

    if (props.priceQuote?.guaranteeMethods?.length !== 1) {
      const { value: guaranteeType, errorMessage: guaranteeTypeError } = VeeValidate.useField('guaranteeType')
      localPayment.guaranteeMethodType = guaranteeType
      formErrors.guaranteeType = guaranteeTypeError
    }

    localPayment.cardType = cardType
    localPayment.cardNumber = cardNumber
    localPayment.validMonth = validMonth
    localPayment.validYear = validYear
    localPayment.cvv = cvv
    localPayment.firstName = firstName
    localPayment.lastName = lastName

    formErrors.firstName = firstNameError
    formErrors.lastName = lastNameError
    formErrors.cardType = cardTypeError
    formErrors.cardNumber = cardNumberError
    formErrors.validMonth = validMonthError
    formErrors.validYear = validYearError
    formErrors.cvv = cvvError

    Vue.onMounted(() => {
      document.querySelector('h1').scrollIntoView()
      try {
        if (!cart.selectedUnits.value || !cart.hasRateId) {
          throw new AppError('unitOrRateMissing', ErrorLevel.WARNING)
        }
        if (isEmpty(cart.client)) {
          throw new AppError('personalDetailsMissing', ErrorLevel.WARNING)
        }
        if (!props.priceQuote?.rateId) {
          throw new Error("no price quote")
        }
        Object.assign(localPayment, cart.payment.value)
        if (props.priceQuote.guaranteeMethods.length === 1) {
          localPayment.method = clone(props.priceQuote.guaranteeMethods[0])
        }
      } catch (err) {
        setErrorAndStopLoading(err)
      }

      DATA_LAYER.bookingFinalConfirmation()
    })

    return {
      localPayment,
      guaranteeMethodType,
      guaranteeMethod,
      formErrors,
      conditions,
      currentYear,
      NO_OF_YEARS,
      isModalOpen,
      modalContent,
      showRatePolicy,
      modalPopupOpen,
      conditionsError,
      onSubmitPayment,
      formElement,
    }
  }
}
