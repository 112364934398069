/* global Vue, USD_CONVERSION_RATE  */

import { formatNumber } from './utils-common'

const _currency = Vue.ref('EUR')

Vue.watch(_currency, newCurrency => {
  localStorage.setItem('currency', newCurrency)
})

_currency.value = localStorage.getItem('currency')

function useCurrencyObserver () {
  const observer = new MutationObserver(([e]) => {
    _currency.value = e.target.dataset.currency
  })

  observer.observe(document.body, {
    attributes: true,
    attributeFilter: ['data-currency'],
  })

  return _currency
}

function setCurrency (currency) {
  document.body.dataset.currency = currency
  _currency.value = currency
}

function changeTextCurrency (text) {
  const matches = /([\d,.]* ?)(?:EUR|€)/gm.exec(text)
  if (_currency.value === 'USD' && matches) {
    const price = formatNumber(parseFloat(matches[1].replace('.', '')) * USD_CONVERSION_RATE, 2, 2)
    return text.replace(matches[0], `$${price}`)
  }
  return text
}

export { useCurrencyObserver, setCurrency, changeTextCurrency }
